<!-- NarrativeTabs.vue -->
<template>
    <div class="narrative-tabs">
      <!-- Tab headers -->
      <div class="tab-headers">
        <button
          v-for="(section, index) in sections"
          :key="index"
          class="tab-button"
          :class="{ 'active': activeTab === index }"
          @click="onTabClick(index)"
        >
          Narrative {{ index + 1 }}
        </button>
      </div>

      <!-- Tab content -->
      <div class="tab-content">
        <div
          v-for="(section, index) in sections"
          :key="index"
          v-show="activeTab === index"
          class="tab-pane"
        >
          <slot :name="'tab-' + index"></slot>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: 'NarrativeTabs',
    props: {
      text: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        activeTab: 0
      }
    },
    methods: {
      onTabClick(index) {
        this.activeTab = index;
        this.$emit('tab-changed', index);
      }
    },
    computed: {
        sections() {
        if (!this.text) return [];

        const matches = this.text.match(/NARRATIVE ANSWER:([^]*?)(?=\n*NARRATIVE ANSWER:|\n*$)/g);
        if (!matches) {
            // If no NARRATIVE ANSWER sections found, return original text as single section
            return [this.text.trim()];
        }

        // Process multiple narrative sections as before
        return matches
            .map(section => section.replace(/NARRATIVE ANSWER:\s*/m, '').trim())
            .filter(section => section.length > 0);
        }
    }
  }
  </script>

  <style scoped>
  .narrative-tabs {
    width: 100%;
    margin: 0 auto;
  }

  .tab-headers {
    display: flex;
    border-bottom: 1px solid #e2e8f0;
    margin-bottom: 1rem;
  }

  .tab-button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #718096;
    background: none;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all 0.2s;
  }

  .tab-button:hover {
    color: #2d3748;
  }

  .tab-button.active {
    color: #4299e1;
    border-bottom-color: #4299e1;
  }

  .tab-content {
    padding: 0;
  }

  .tab-pane {
    width: 100%;
  }
  </style>
