<template>
    <div class="faq-container">
      <div class="header">
        <router-link :to="{ name: 'chat' }" class="home-link">
          <img src="/static/unstruct-logo-new.png" alt="UnStruct Logo" width="55" height="55" />
        </router-link>
        <h1>Tuning, Settings & Usage FAQ</h1>
      </div>
      <div class="preamble">
        <h2>Welcome to the Unstruct Control Center!
        </h2>
        <p>
          Yes, we know - there are a lot of settings here. But that's not a bug, it's a feature!
          Unstruct is designed for those who love to tinker, experiment, and squeeze every ounce of
          performance out of their tools. Each knob and dial gives you the power to fine-tune your
          experience and push the boundaries of what's possible.
        </p>
        <p>
          Don't be overwhelmed - be excited! This FAQ, along with the tooltips sprinkled throughout
          the product, are your trusty lab assistants. They're here to guide you through the
          wonderful world of UnStruct's capabilities. So grab your virtual lab coat, and let's dive
          into the fascinating realm of advanced RAG strategies, LLM fine-tuning, and more!
        </p>
        <v-alert type="info" outlined>
          <strong>Pro Tip:</strong> The most important thing to pay attention to when using UnStruct is the RAG context
          of the active object (IAO) you are interacting with. The RAG strategy is automatically selected based
          on existing context. For example, immediately after a web search, the RAG strategy is changed, so
          you can dig deep into the search results. This context is sticky (for all the follow-up questions on the same set of search results) until you change it. This is by design
          to allow you to get the most out of the context you are working with.
        </v-alert>
      </div>
      <div class="faq-panels">
        <div v-for="(faq, index) in faqs" :key="index" class="faq-panel">
          <div class="faq-question" @click="toggleFaq(index)">
            <span class="question-text">{{ faq.question }}</span>
            <span class="toggle-icon">{{ faq.isOpen ? '▼' : '▶' }}</span>
          </div>
          <transition name="fade">
            <div v-if="faq.isOpen" class="faq-answer" v-html="faq.answer"></div>
          </transition>
          <v-divider v-if="index < faqs.length - 1"></v-divider>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: 'FAQ',
    data() {
      return {
        faqs: [
        {
            question: "How is knowledge organized in UnStruct?",
            answer: `
                <div class="knowledge-universe">
                <svg width="400" height="300" viewBox="0 0 400 300" class="knowledge-universe-diagram">
                    <defs>
                    <radialGradient id="universeGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                        <stop offset="0%" style="stop-color:rgb(60,60,120);stop-opacity:1" />
                        <stop offset="100%" style="stop-color:rgb(20,20,40);stop-opacity:1" />
                    </radialGradient>
                    </defs>
                    <circle cx="200" cy="150" r="140" fill="url(#universeGradient)" />
                    <text x="200" y="30" text-anchor="middle" fill="#004bfb" font-size="16">Universe (Organization)</text>
                    <circle cx="200" cy="150" r="110" fill="none" stroke="#4B0082" stroke-width="2" stroke-dasharray="5,5" />
                    <text x="200" y="60" text-anchor="middle" fill="#FFF" font-size="14">Galaxy (Project)</text>
                    <circle cx="200" cy="150" r="80" fill="#008000" stroke="#004bfb" stroke-width="2" opacity="0.7" />
                    <text x="200" y="150" text-anchor="middle" fill="#FFF" font-size="12">Planet (Topic)</text>
                    <circle cx="260" cy="180" r="30" fill="#FFA500" stroke="#004bfb" stroke-width="2" opacity="0.8" />
                    <text x="260" y="175" text-anchor="middle" fill="#000" font-size="10">Moon</text>
                    <text x="260" y="190" text-anchor="middle" fill="#000" font-size="10">(Sub-topic)</text>
                    <circle cx="160" cy="200" r="20" fill="#FF4500" stroke="#004bfb" stroke-width="2" />
                    <text x="160" y="205" text-anchor="middle" fill="#FFF" font-size="10">IAO</text>
                    <circle cx="230" cy="120" r="15" fill="#FFD700" stroke="#004bfb" stroke-width="2" />
                    <text x="230" y="125" text-anchor="middle" fill="#000" font-size="8">Entity</text>
                </svg>
                <p>Your knowledge is organized in this cosmic hierarchy:</p>
                <ul>
                    <li><strong>Universes:</strong> Organizations or main domains of knowledge.</li>
                    <li><strong>Galaxies:</strong> Major projects within a universe.</li>
                    <li><strong>Planets:</strong> Individual topics or subjects within a project.</li>
                    <li><strong>Sub-planets/Moons:</strong> Sub-topics or related concepts within a topic.</li>
                    <li><strong>Interactive Active Objects (IAOs):</strong> Living, breathing things within your knowledge structure.</li>
                    <li><strong>Entities:</strong> Actual data broken into manageable data objects.</li>
                </ul>
                <p>As you add information, it will automatically (based on your settings) be categorized into this hierarchy, allowing for intuitive navigation and powerful, context-aware searches. The structure enables you to organize from broad concepts (Universes) down to specific data points (Entities), with IAOs providing dynamic, interactive elements throughout.</p>
                </div>
            `,
            isOpen: false
        },
        {
            question: "When using \"web_search\" as the RAG strategy, can I select my sources?",
            answer: "Absolutely! Choose your sources; shape your understanding. When you select the \"web_search\" RAG strategy, you can choose how many search results, which site(s) to include, which to exclude, etc. You also have the ability to skip top search results (e.g skip the top 10 or 20 results), to get a more diverse set of results. You can even specify the date range (e.g. only in the past week) of the search results for that extra level of control and precision.",
            isOpen: false
        },
        {
            question: "What are those objects in the job updates feed that show up after I ask a query using web_search as the RAG strategy?",
            answer: "The object with the chat icon is the user query which, when selected, aggregates RAG from all the sites from the search results. Alternatively, you can select each site individually, and the RAG context will be narrowed/focused to that object. We feel this is a great way to explore the content from different sources and get a more comprehensive understanding of the topic. All the objects in the feed are clickable and can be selected to view the RAG context.",
            isOpen: false
        },
        {
            question: "What about the objects that show up after I submit text, file/image, or a YouTube video?",
            answer: "Same as above. The RAG context is then focused on the submitted content after the extraction process.",
            isOpen: false
        },
        {
            question: "Can I view the RAG context for each object that is sent to the LLMs for generating responses?",
            answer: "Yes, you can! Click on the object in the feed to view the RAG context. This will show you (after you've submitted your query) the content that was sent to the LLMs for generating responses. The content is automatically highlighted in the RAG context window based on the keywords in the query, but you can also manually highlight text in the RAG context either by selecting text from either the answer (using your mouse; just like how you would select text for a copy/paste), or by using the search bar in the RAG context window.",
            isOpen: false
        },
        {
            question: "Wow, that is a powerful feature! Can I also view the RAG context for the entities extracted from the content?",
            answer: "Yes, you can! When you select ECRAG as the strategy, the content from the entities is also shown in the RAG context window. You can even click on the entities to understand the context in which they were extracted. This is a great way to understand the relationships between entities and the content they were extracted from.",
            isOpen: false
        },
        {
            question: "I just submitted 10 files that are all related to the same topic. How do I group them together?",
            answer: "That's where the Universe, Galaxy, and Planet hierarchy comes into play. You can create a new Galaxy within the Universe (or use the Default \"My First Galaxy\"), and then create a new Planet within that Galaxy (or use the default \"My First Planet\") -- both auto-created for your convenience. Then, select that Planet when submitting the files, and they will all be grouped together under that topic.",
            isOpen: false
        },
        {
            question: "Okay, great, but how do I extract insights from those 10 files?",
            answer: "Go to planets (from contols & settings) and select the planet you want to extract insights from. Selecting a planet will automatically narrow the RAG context to focus that planet (and any sub-planets/moons within it). Then, you can ask questions, run analysis, and extract insights from the content within that planet.",
            isOpen: false
        },
        {
            question: "Well, ok, I selected the planet; what RAG strategy should I use?",
            answer: "Great question! You have two options: LLM_passthrough and ECRAG. LLM_passthrough is automatically selected when you select a planet. This strategy uses your chosen LLM model to generate responses based on the content within the planet. Pretty simple, right? Well, as long as the RAG tokens from all the files are within the context window (200k, 1M, etc.) of the chosen LLM model, you should be good to go. If the context length is too long, UnStruct dynamically switches the RAG strategy to ECRAG to handle the longer context -- but only if related entities are found. If no entities are found, the content is trimmed to fit the LLM context window.",
            isOpen: false
        },
        {
            question: "Awesome. But, how do I build these entities?",
            answer: "You are on the right track! You can build entities by checking the \"Build Index\" checkbox when submitting content. This will create entities from the content and store them in the backend. Note that this feature requires a pro plan.",
            isOpen: false
        },
        {
            question: "But why choose ECRAG over LLM_passthrough?",
            answer: "ECRAG is a more advanced strategy that uses the ECRAG model to generate responses. This model is designed to handle longer context windows and is more robust when dealing with complex, multi-faceted, interlinked content. If you're working with long documents, multiple documents, or content that requires a deep understanding of the context, or find hidden relationships between entities, ECRAG is the way to go.",
            isOpen: false
        },
        {
            question: "I've read that the secret of LLMs is that \"garbage in equals garbage out\". How do I ensure my content is clean?",
            answer: "Great question! We've developed ECRAG mainly to handle this issue. ECRAG is designed to filter out irrelevant content and focus on the most important entities and relationships. If you're working with a lot of content, or content that's not well-organized, ECRAG can help you make sense of it all. It's not perfect, but it's a great tool to help you get started.",
            isOpen: false
        },
        {
            question: "Why the extra step of showing the \"Knowledge Empowerment Tuning\" dialog when asking a related question or fine-tuning the answer?",
            answer: "We think the flow is intuitive and natural - you ask a question, get a response, and then have the option to fine-tune the response. This allows you to adjust the context window, RAG strategy, and other settings to get the best possible answer. It's all about empowering you to get the information you need, when you need it.",
            isOpen: false
        },
        {
            question: "All this is great, but constantly having to context switch between objects, strategies, settings, etc. can be a bit overwhelming. How do I simplify this?",
            answer: "We understand that the platform can be a bit complex, especially for new users. That's why we've included tooltips, FAQs, and other resources to help you get started. Based on our own use of the product, we've tried to make most of the flow as dynamic and automated as possible, but we also wanted to give you the power to fine-tune the settings when you need to. If you're finding it overwhelming, don't worry, after a few uses, you'll get the hang of it. Of course, if you have any questions, or suggestions to make the platform easier to use, we're all ears!",
            isOpen: false
        },
        {
            question: "The limits seem low. How do I increase them?",
            answer: "We've set the limits to ensure that everyone has a fair chance to use the platform as we scale. If you need more, you can upgrade to a Pro plan, which gives features like ECRAG, higher limits, and more powerful insights. We think the Pro plan should satisfy most users, but if you need even more, we can work with you to create a custom plan that meets your needs.",
            isOpen: false
        },
        {
            question: "Can the \"Pro Team\" plan have only a single user?",
            answer: "Yes, you always start with a single user, and then add more members as needed. The billing is automatically adjusted based on the number of users/seats in your organization. If you're the only user, you'll be billed for a single user. If you add more users, you'll be billed for each additional user",
            isOpen: false
        },
        {
            question: "How does anonymous access work in UnStruct?",
            answer: `
                <p>UnStruct offers a unique privacy-first approach:</p>
                <ul>
                    <li>Start using instantly - no email or registration required</li>
                    <li>Access most features without an account</li>
                    <li>Your session is controlled by a secure anonymous key stored in your browser</li>
                    <li>No tracking, no personal data collection</li>
                </ul>`,
            isOpen: false
        },
        {
            question: "What happens to my anonymous key if I clear my browser data?",
            answer: `
                <p>If you clear your browser data, you'll lose access to your anonymous key:</p>
                <ul>
                    <li>Your session will be reset, and you'll lose access to your data</li>
                    <li>We recommend creating a registered account to avoid losing access</li>
                </ul>`,
            isOpen: false
        },
        {
            question: "Can I upgrade to Pro while staying anonymous?",
            answer: `
                <p>No, upgrading to Pro requires a registered account:</p>
                <ul>
                    <li>The reason is that if you lose access to your key, you'll lose your subscription</li>
                    <li>Register with an email to create a new account</li>
                    <li>Cancel anytime with one click from within the app</li>
                    <li>Registering also enables cross-device sync and access management</li>
                </ul>`,
            isOpen: false
        },
        {
            question: "Can I convert my anonymous account to a registered account later?",
            answer: "Currently, there's no direct way to convert an anonymous account to a registered one. If you want persistent access management and automatic cross-device sync, we recommend starting with a registered account.",
            isOpen: false
        },
        ]
      }
    },
    methods: {
      toggleFaq(index) {
        this.faqs[index].isOpen = !this.faqs[index].isOpen;
      }
    }
  }
  </script>

  <style scoped>
  .faq-container {
    max-width: 800px;
    margin: 0 auto 100px auto;
    padding: 20px;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .home-link {
    margin-right: 20px;
  }

  .preamble {
    border-left: 4px solid #4a90e2;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 4px;
  }

  .preamble h2 {
    color: #4a90e2;
    margin-top: 0;
  }

  .faq-panels {
    margin-top: 20px;
  }

  .faq-panel {
    border-radius: 8px;
    margin-bottom: 10px;
    overflow: hidden;
  }

  .faq-question {
    padding: 15px;
    cursor: pointer;
    font-size: 1.155em;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .question-text {
    flex: 1;
    margin-right: 20px;
  }

  .toggle-icon {
    font-size: 12px;
    margin-top: 3px;
  }

  .faq-answer {
    padding: 0 15px 15px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .knowledge-universe {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.knowledge-universe-diagram {
  max-width: 100%;
  height: auto;
}

.knowledge-universe ul {
  text-align: left;
  margin-top: 20px;
}

.knowledge-universe p {
  text-align: left;
  margin-top: 20px;
}
  </style>
