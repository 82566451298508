<template>
    <div class="search-thumbnails-simple" v-if="processedResults.length">
      <transition-group name="thumbnail-fade" tag="div" class="thumbnails-grid">
        <div
          v-for="result in processedResults"
          :key="result.id"
          class="thumbnail-item"
          @click="handleClick(result)"
        >
          <div class="thumbnail-wrapper">
            <img
              v-if="result.thumbnail || result.image"
              :src="result.thumbnail || result.image"
              :alt="result.title"
              @error="handleImageError($event, result)"
            >
            <div v-else class="thumbnail-placeholder">
              {{ getDomainInitials(result.url) }}
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </template>

  <script>
  export default {
    name: 'SearchThumbnailsSimple',
    props: {
      results: {
        type: Array,
        required: true
      },
      isSearching: {
        type: Boolean,
        default: false
      },
      finalAnswerReceived: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        processedResults: []
      }
    },

    watch: {
      results: {
        immediate: true,
        handler(newResults) {
          if (newResults?.length) {
            this.processedResults = newResults.map(result => ({
              ...result,
              id: result.id || Math.random().toString(36).substr(2, 9)
            }));
          }
        }
      }
    },

    methods: {
      getDomainInitials(url) {
        try {
          const domain = new URL(url).hostname.replace('www.', '');
          return domain.split('.')[0].substring(0, 2).toUpperCase();
        } catch (e) {
          return '??';
        }
      },

      handleImageError(event, result) {
        event.target.style.display = 'none';
        const placeholder = event.target.parentElement.querySelector('.thumbnail-placeholder');
        if (placeholder) {
          placeholder.style.display = 'flex';
        }
      },

      handleClick(result) {
        this.$emit('thumbnail-click', result);
      }
    }
  }
  </script>

  <style scoped>
  .search-thumbnails-simple {
    grid-column: 3;
    width: 200px;
    background: var(--surface-overlay);
    backdrop-filter: blur(400px);
    overflow-y: auto;
    position: sticky;
    top: 60px;
    height: calc(100vh - 60px);
    scrollbar-width: thin;
    scrollbar-color: rgba(var(--v-theme-on-surface), 0.12) transparent;
    padding: 1rem;
    border-left: 1px solid rgba(99, 102, 241, 0.1);
    }

  .thumbnails-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  .thumbnail-item {
    cursor: pointer;
    transition: transform 0.2s ease;
  }

  .thumbnail-item:hover {
    transform: scale(1.05);
  }

  .thumbnail-wrapper {
    aspect-ratio: 1;
    border-radius: 4px;
    overflow: hidden;
    background: rgba(4, 255, 134, 0.1);
    border: 1px solid rgba(4, 255, 134, 0.2);
    position: relative;
  }

  .thumbnail-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .thumbnail-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, rgba(4, 255, 134, 0.1), rgba(4, 255, 134, 0.2));
    font-size: 1rem;
    color: rgba(4, 255, 134, 0.8);
  }

  .thumbnail-fade-enter-active,
  .thumbnail-fade-leave-active {
    transition: all 0.3s ease;
  }

  .thumbnail-fade-enter-from,
  .thumbnail-fade-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
  </style>
