<template>
  <div class="status-container">
    <div class="cosmic-background">
      <div class="star-field"></div>
      <div class="neural-grid"></div>
    </div>
    <div class="status-grid">
      <div v-for="(message, index) in messageHistory"
           :key="index"
           class="status-item"
           :class="{ 'latest': index === messageHistory.length - 1 }">
        <div class="status-indicator">
          <div class="synapse-node">
            <div class="node-core"></div>
            <div class="node-pulse"></div>
            <div class="node-ring"></div>
          </div>
          <div class="neural-path" v-if="!isLastInColumn(index)"></div>
        </div>
        <div class="status-content">
          <div class="status-text-wrapper">
            <div class="brain-wave" v-if="index === messageHistory.length - 1">
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
            </div>
            <div class="text-container">
              <span class="status-text">{{ message.message }}</span>
              <span v-if="typing && index === messageHistory.length - 1" class="quantum-dots">
                <span class="quantum-dot"></span>
                <span class="quantum-dot"></span>
                <span class="quantum-dot"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModernStatusMessages',
  data() {
    return {
      messageHistory: []
    }
  },
  props: {
    statusMessage: String,
    typing: Boolean
  },
  computed: {
    currentColumns() {
      const totalItems = this.messageHistory.length
      if (totalItems <= 4) return 1
      if (totalItems <= 8) return 2
      return 3
    }
  },
  watch: {
    statusMessage(newMsg) {
      if (newMsg) {
        this.messageHistory.push({
          message: newMsg
        });
      }
    }
  },
  methods: {
    isLastInColumn(index) {
      const itemsPerColumn = Math.ceil(this.messageHistory.length / this.currentColumns)
      return (index + 1) % itemsPerColumn === 0
    }
  }
}
</script>

<style scoped>
.status-container {
  width: 100%;
  height: 100vh;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
}

.cosmic-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
}

.star-field {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(1px 1px at 20px 30px, #fff, rgba(0,0,0,0)),
              radial-gradient(1px 1px at 40px 70px, #fff, rgba(0,0,0,0)),
              radial-gradient(1px 1px at 50px 160px, #fff, rgba(0,0,0,0)),
              radial-gradient(1px 1px at 90px 40px, #fff, rgba(0,0,0,0));
  background-repeat: repeat;
  background-size: 200px 200px;
  animation: starFloat 100s linear infinite;
  opacity: 0.1;
}

.neural-grid {
  position: absolute;
  width: 100%;
  height: 100%;
  background:
    linear-gradient(90deg, rgba(79, 236, 184, 0.03) 1px, transparent 1px),
    linear-gradient(0deg, rgba(79, 236, 184, 0.03) 1px, transparent 1px);
  background-size: 20px 20px;
  animation: gridFloat 60s linear infinite;
}

.status-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  position: relative;
  z-index: 1;
}

.status-item {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 12px;
  position: relative;
}

.synapse-node {
  position: relative;
  width: 12px;
  height: 12px;
}

.node-core {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #4FECB8;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.node-pulse {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(79, 236, 184, 0.3);
  animation: synapseActivate 2s ease-out infinite;
}

.node-ring {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(79, 236, 184, 0.5);
  border-radius: 50%;
  animation: synapseRotate 4s linear infinite;
}

.neural-path {
  position: absolute;
  left: 6px;
  top: 16px;
  bottom: -8px;
  width: 2px;
  background: linear-gradient(to bottom,
    rgba(79, 236, 184, 0.5),
    rgba(79, 236, 184, 0.1)
  );
}

.status-content {
  background: rgba(15, 23, 42, 0.6);
  backdrop-filter: blur(12px);
  border-radius: 16px;
  padding: 12px 16px;
  border: 1px solid rgba(79, 236, 184, 0.2);
  box-shadow:
    0 4px 20px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(79, 236, 184, 0.1),
    inset 0 0 20px rgba(79, 236, 184, 0.05);
  overflow: hidden;
}

.text-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  mask-image: linear-gradient(to right, black 90%, transparent 100%);
  -webkit-mask-image: linear-gradient(to right, black 90%, transparent 100%);
}

.status-text {
  font-family: 'SF Pro Display', system-ui, sans-serif;
  font-size: 0.875rem;
  background: linear-gradient(90deg, #fff, #4FECB8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.brain-wave {
  position: relative;
  width: 20px;
  height: 20px;
}

.wave {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #4FECB8;
  opacity: 0.5;
}

.wave:nth-child(1) { animation: waveFloat 2s ease-in-out infinite; }
.wave:nth-child(2) { animation: waveFloat 2s ease-in-out infinite 0.2s; }
.wave:nth-child(3) { animation: waveFloat 2s ease-in-out infinite 0.4s; }

.quantum-dots {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-left: 8px;
}

.quantum-dot {
  width: 4px;
  height: 4px;
  background: #4FECB8;
  border-radius: 50%;
  opacity: 0;
  animation: quantumPulse 1.5s ease-in-out infinite;
}

.quantum-dot:nth-child(2) { animation-delay: 0.2s; }
.quantum-dot:nth-child(3) { animation-delay: 0.4s; }

/* Latest item enhancements */
.latest .status-content {
  border-color: rgba(79, 236, 184, 0.3);
  box-shadow:
    0 8px 32px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(79, 236, 184, 0.2),
    inset 0 0 30px rgba(79, 236, 184, 0.1);
}

.latest .status-content {
  position: relative;
}

.latest .status-content::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
  box-shadow: 0 0 20px rgba(79, 236, 184, 0.15);
  opacity: 0;
  animation: glowPulse 2s ease-in-out infinite;
}

@keyframes glowPulse {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes synapseActivate {
  0% { transform: scale(0.8); opacity: 0.8; }
  50% { transform: scale(1.5); opacity: 0; }
  100% { transform: scale(0.8); opacity: 0.8; }
}

@keyframes synapseRotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes waveFloat {
  0%, 100% { transform: scaleY(1) translateY(0); }
  50% { transform: scaleY(2) translateY(-2px); }
}

@keyframes quantumPulse {
  0%, 100% { transform: scale(0.8); opacity: 0.3; }
  50% { transform: scale(1.2); opacity: 1; }
}

@keyframes starFloat {
  0% { background-position: 0 0; }
  100% { background-position: -200px -200px; }
}

@keyframes gridFloat {
  0% { transform: translateX(0) translateY(0); }
  100% { transform: translateX(-20px) translateY(-20px); }
}

@media (max-width: 768px) {
  .status-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
</style>
