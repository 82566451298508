<template>
  <v-slide-y-transition>
    <div v-if="selectedText" class="floating-action-box" :style="positionStyle">
      <div class="action-buttons">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              small
              color="tips"
              class="action-btn"
              v-bind="attrs"
              v-on="on"
              @click="handleAction('explain')"
            >
              <v-icon small left>mdi-lightbulb-outline</v-icon>
              Explain This
            </v-btn>
          </template>
          <span>Get a detailed explanation of this concept</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              small
              color="error"
              class="action-btn"
              v-bind="attrs"
              v-on="on"
              @click="handleAction('compare')"
            >
              <v-icon small left>mdi-compare</v-icon>
              Compare
            </v-btn>
          </template>
          <span>Compare this with related concepts</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              small
              color="success"
              class="action-btn"
              v-bind="attrs"
              v-on="on"
              @click="handleAction('example')"
            >
              <v-icon small left>mdi-code-brackets</v-icon>
              Examples
            </v-btn>
          </template>
          <span>See practical examples</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              small
              color="warning"
              class="action-btn"
              v-bind="attrs"
              v-on="on"
              @click="handleAction('implications')"
            >
              <v-icon small left>mdi-arrow-decision-outline</v-icon>
              Implications
            </v-btn>
          </template>
          <span>Understand broader implications</span>
        </v-tooltip>

        <v-btn
          text
          small
          color="error"
          class="action-btn"
          @click="cancelSelection"
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </v-slide-y-transition>
</template>

<script>
export default {
  props: {
    selectedText: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      positionStyle: {
        bottom: '100%',
        left: '50%',
        transform: 'translateX(-50%)'
      },
      actionTemplates: {
        explain: {
          prefix: "Help me understand in detail: ",
          suffix: ". Please break it down step by step."
        },
        compare: {
          prefix: "How does this concept compare to related approaches: ",
          suffix: ". Please highlight key differences and trade-offs."
        },
        example: {
          prefix: "Can you provide practical examples of: ",
          suffix: ". Include real-world applications where possible."
        },
        implications: {
          prefix: "What are the broader implications and potential impacts of: ",
          suffix: ". Consider both immediate and long-term effects."
        }
      }
    };
  },

  methods: {
    handleAction(type) {
      const template = this.actionTemplates[type];
      const formattedQuestion = `${template.prefix}${this.selectedText}${template.suffix}`;
      this.$emit('use-selection', formattedQuestion);
      this.cancelSelection();
    },

    cancelSelection() {
      this.$emit('cancel');
    }
  }
};
</script>

<style scoped>
.floating-action-box {
  position: fixed;
  transform: translateX(-50%);
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
  border-radius: 12px;
  padding: 4px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 100;
}

.theme--light .floating-action-box {
  background: linear-gradient(
    to right,
    rgba(147, 197, 253, 0.1) 0%,
    rgba(96, 165, 250, 0.15) 50%,
    rgba(147, 197, 253, 0.1) 100%
  );
  border: 1px solid rgba(96, 165, 250, 0.15);
  box-shadow: 0 2px 10px rgba(147, 197, 253, 0.1);
}

.action-buttons {
  display: flex;
  gap: 4px;
  align-items: center;
}

.action-btn {
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-weight: 500 !important;
  min-width: 0 !important;
  padding: 0 12px !important;
}

/* Hover effects */
.action-btn:hover {
  background: rgba(255, 255, 255, 0.05) !important;
}

/* Optional: Add transition for smoother interaction */
.v-slide-y-transition-enter-active,
.v-slide-y-transition-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
}
</style>
