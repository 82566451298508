<template>
  <div class="search-results-visualization" :class="{ 'collapsed': isCollapsed }">
    <!-- Neural Animation Row - Always visible when searching -->
    <div v-if="!finalAnswerReceived" class="neural-row">
      <div class="status-wrapper">
        <div class="status-icon">
          <div class="neural-animation">
            <div class="neural-pulse"></div>
            <div class="neural-ring"></div>
          </div>
        </div>
        <div class="status-content">
          <div class="status-progress">
            <div class="progress-bar"></div>
          </div>
        </div>
      </div>
      <div class="results-counter">
        <span class="counter-number">{{ processedResults.length }}</span>
        <span class="counter-separator">/</span>
        <span class="counter-total">{{ results.length }}</span>
      </div>
    </div>

    <!-- Thumbnails Row -->
    <div class="thumbnails-row" @click="toggleCollapse">
      <div class="thumbnail-grid">
        <div v-for="(result, index) in displayThumbnails"
            :key="index"
            class="mini-thumbnail"
            :class="{
              'auto-scrolling-layout': autoScrollingLayout,
              'is-rag-sources-view': isRagSourcesView,
              'speaking': result.url === activeSpeakingUrl
            }"
            :style="{ zIndex: displayThumbnails.length - index }">
          <img v-if="result.thumbnail || result.image"
              :src="result.thumbnail || result.image"
              :alt="result.title"
              @error="handleImageError">
          <div v-else class="mini-thumbnail-placeholder"></div>
        </div>
        <div v-if="processedResults.length > thumbnailStripCount" class="thumbnail-more">
          +{{ processedResults.length - thumbnailStripCount }}
        </div>
      </div>
      <div class="collapse-icon">
        <i :class="isCollapsed ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'"></i>
      </div>
    </div>

    <!-- Expanded Results List -->
    <transition-group name="result-transition" tag="div" class="results-container" v-if="!isCollapsed">
      <div v-for="(result) in processedResults" :key="result.id" class="result-item">
        <div class="result-connector">
          <div class="connector-line"></div>
          <div class="connector-dot"></div>
        </div>
        <div class="result-content">
          <div class="result-header">
            <div class="result-image"
              :class="{ 'speaking': result.url === activeSpeakingUrl }">
              <img v-if="result.thumbnail || result.image"
                  :src="result.thumbnail || result.image"
                  :alt="result.title"
                  @error="handleImageError">
              <svg v-else width="150" height="90" viewBox="0 0 150 90" class="placeholder-svg">
                <!-- Keeping existing SVG placeholder code -->
                <defs>
                  <linearGradient id="placeholder-grad" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style="stop-color:#f3f4f6"/>
                    <stop offset="100%" style="stop-color:#e5e7eb"/>
                  </linearGradient>
                  <pattern id="placeholder-pattern" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
                    <circle cx="5" cy="5" r="1" fill="#d1d5db" opacity="0.3"/>
                  </pattern>
                </defs>
                <rect width="150" height="90" fill="url(#placeholder-grad)" rx="4" ry="4"/>
                <rect width="150" height="90" fill="url(#placeholder-pattern)" rx="4" ry="4"/>
                <g transform="translate(60,30)">
                  <path d="M0 25 L10 15 L20 22 L30 5 L40 25" stroke="#9ca3af" stroke-width="2" fill="none"/>
                  <circle cx="25" cy="10" r="4" fill="#9ca3af"/>
                </g>
                <rect x="25" y="65" width="100" height="15" fill="rgba(255,255,255,0.9)" rx="2"/>
                <text x="75" y="76" text-anchor="middle" fill="#4b5563" font-size="10" font-family="system-ui, -apple-system, sans-serif">
                  {{ truncateTitle(result.title) }}
                </text>
              </svg>
            </div>
            <div class="result-info">
              <div class="result-title" v-if="result.title">{{ result.title }}</div>
              <div class="result-date" v-if="result.date">{{ formatDate(result.date) }}</div>
              <a :href="result.url" target="_blank" rel="noopener noreferrer" class="result-url">
                {{ getDomainFromUrl(result.url) }}
              </a>
            </div>
          </div>
          <div class="result-description" v-if="result.description">{{ result.description }}</div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'SearchResultsAnimation',
  props: {
    autoScrollingLayout: {
      type: Boolean,
      default: false
    },
    isRagSourcesView: {
      type: Boolean,
      default: false
    },
    results: {
      type: Array,
      required: true
    },
    isSearching: {
      type: Boolean,
      default: false
    },
    finalAnswerReceived: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeSpeakingUrl: null,
      isSearchingLocal: false,
      processedResults: [],
      statusMessages: [
        'Initializing neural pathways...',
        'Analyzing semantic patterns...',
        'Synthesizing knowledge graph...',
        'Optimizing relevance matrix...',
        'Correlating data streams...',
        'Mapping conceptual networks...',
        'Aligning information vectors...',
        'Calibrating response models...'
      ],
      completedMessage: 'Search complete',
      currentStatusIndex: 0,
      isCollapsed: true,
    }
  },
  computed: {
    thumbnailStripCount() {
      return this.isMobile ? 30 : 30
    },
    isMobile() {
      return window.innerWidth <= 768
    },
    displayThumbnails() {
      return this.processedResults.slice(0, this.thumbnailStripCount)
    },
    statusMessage() {
      return this.finalAnswerReceived ? this.completedMessage : this.statusMessages[this.currentStatusIndex]
    }
  },
  methods: {
    setActiveSpeakingUrl(url) {
      this.activeSpeakingUrl = url;
    },
    getDomainFromUrl(url) {
      try {
        const urlObject = new URL(url);
        return urlObject.hostname.replace('www.', '');
      } catch (e) {
        // If URL parsing fails, return the original URL
        return url;
      }
    },
    truncateTitle(title) {
      return title ? (title.length > 15 ? title.substring(0, 15) + '...' : title) : 'No Title';
    },
    handleImageError(e) {
      e.target.style.display = 'none';
      e.target.parentElement.style.display = 'none';  // Hide the container too
    },
    formatDate(dateStr) {
      if (!dateStr) return 'No date available';

      // Handle ISO dates
      if (dateStr.includes('T')) {
        return new Date(dateStr).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      }

      // For dates already in readable format, return as is
      return dateStr;
    },
    animateResults(results) {
      results.forEach((result, index) => {
        setTimeout(() => {
          this.processedResults.push(result)
          this.currentStatusIndex = (index % this.statusMessages.length)
        }, index * 100)
      })
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed
      this.$emit('collapsed', this.isCollapsed)
    }
  },
  watch: {
    results: {
      immediate: true,
      handler(newResults) {
        if (newResults.length === 0) return
        this.isSearchingLocal = this.isSearching
        this.processedResults = []
        if (newResults.length > 0) {
          this.animateResults(newResults)
        }
      }
    },
    finalAnswerReceived: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.isSearchingLocal = false
          this.$emit('update:finalAnswer', true)
          setTimeout(() => {
            this.isCollapsed = true
            this.$emit('collapsed', true)
          }, 300)
        }
      }
    }
  }
}
</script>

<style scoped>
.search-results-visualization {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.search-results-visualization.collapsed {
  padding: 0.5rem;
}

/* Neural Animation Row */
.neural-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 12px;
  backdrop-filter: blur(12px);
  background: rgba(4, 255, 134, 0.05);
  border: 1px solid rgba(4, 255, 134, 0.1);
}

/* Thumbnails Row */
.thumbnails-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 12px;
  backdrop-filter: blur(12px);
  background: rgba(4, 255, 134, 0.05);
  cursor: pointer;
}

.thumbnail-grid {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-template-rows: repeat(4, auto);
  gap: 6px;
  flex-grow: 1;
}

.search-status-bar {
  backdrop-filter: blur(12px);
  padding: 0.5rem;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  max-width: 1200px;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.status-wrapper {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex: 1;
}

.neural-animation {
  position: relative;
  width: 24px;
  height: 24px;
}

.neural-pulse {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(4, 255, 134, 0.3);
  animation: neuralPulse 2s infinite;
}

.neural-ring {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #04ff86;
  border-radius: 50%;
  animation: neuralRing 2s infinite;
}

.status-content {
  flex-grow: 1;
}

.status-text {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  -webkit-background-clip: text;
  margin-bottom: 0.5rem;
}

.status-progress {
  height: 2px;
  background: rgba(4, 255, 134, 0.2);
  border-radius: 2px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #04ff86, #7affc1);
  animation: progress 2s infinite;
  border-radius: 2px;
}

.meta-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.results-counter {
  font-family: 'SF Mono', Monaco, monospace;
  background: rgba(4, 255, 134, 0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  border: 1px solid rgba(4, 255, 134, 0.2);
}

.counter-number {
  color: #04ff86;
  font-weight: 600;
}

.counter-separator {
  margin: 0 0.25rem;
  opacity: 0.5;
}

.counter-total {
  opacity: 0.7;
}

.completion-icon {
  color: #10B981;
  font-size: 1.5rem;
  animation: completionPop 0.3s ease-out;
}

.results-container {
  position: relative;
  transition: all 0.3s ease;
}

.result-item {
  display: flex;
  align-items: flex-start;
  margin: 1rem 0;
  position: relative;
}

.result-connector {
  width: 24px;
  margin-right: 1rem;
  position: relative;
}

.connector-line {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  background: rgba(4, 255, 134, 0.2);
  transform: translateX(-50%);
}

.connector-dot {
  width: 8px;
  height: 8px;
  background: #04ff86;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 12px rgba(4, 255, 134, 0.3);
  animation: connectorPulse 2s infinite;
}

.result-content {
  flex-grow: 1;
  background: linear-gradient(
    135deg,
    rgba(4, 255, 134, 0.06) 0%,
    rgba(4, 255, 134, 0.1) 100%
  );
  padding: 1rem;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.05),
    0 0 8px rgba(4, 255, 134, 0.2);
  backdrop-filter: blur(8px);
}

.result-content:hover {
  transform: translateY(-2px);
  box-shadow:
    0 8px 16px -4px rgba(0, 0, 0, 0.1),
    0 4px 8px -2px rgba(4, 255, 134, 0.15);
  border-color: rgba(4, 255, 134, 0.25);
  background: linear-gradient(
    135deg,
    rgba(4, 255, 134, 0.08) 0%,
    rgba(4, 255, 134, 0.14) 100%
  );
}

.result-url {
  font-size: 0.9rem;
  color: #04ff86;
  text-decoration: none;
  display: inline-block;
  word-break: break-all;
  padding: 0.25rem 0;
  position: relative;
  transition: all 0.2s ease;
}

.result-name {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 0.5rem;
}

@keyframes neuralPulse {
  0% { transform: scale(0.8); opacity: 0.8; box-shadow: 0 0 20px rgba(4, 255, 134, 0.4); }
  50% { transform: scale(1.2); opacity: 0.2; box-shadow: 0 0 40px rgba(4, 255, 134, 0.6); }
  100% { transform: scale(0.8); opacity: 0.8; box-shadow: 0 0 20px rgba(4, 255, 134, 0.4); }
}

@keyframes neuralRing {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

@keyframes progress {
  0% { transform: translateX(-100%); }
  50% { transform: translateX(0); }
  100% { transform: translateX(100%); }
}

@keyframes completionPop {
  0% { transform: scale(0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

@keyframes connectorPulse {
  0% { transform: translate(-50%, -50%) scale(1); opacity: 1; box-shadow: 0 0 20px rgba(4, 255, 134, 0.4); }
  50% { transform: translate(-50%, -50%) scale(1.5); opacity: 0.5; box-shadow: 0 0 40px rgba(4, 255, 134, 0.6); }
  100% { transform: translate(-50%, -50%) scale(1); opacity: 1; box-shadow: 0 0 20px rgba(4, 255, 134, 0.4); }
}

.result-transition-enter-active {
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.result-transition-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.result-transition-enter-from {
  opacity: 0;
  transform: translateY(20px) scale(0.95);
}

.result-transition-leave-to {
  opacity: 0;
  transform: translateY(-20px) scale(0.95);
}

.result-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.result-title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4;
}

.result-description {
  font-size: 0.9rem;
  line-height: 1.5;
  margin-top: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Update result-url styles */
.result-url {
  font-size: 0.85rem;
  color: #6366f1;
  opacity: 0.8;
}
.result-header {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.75rem;
}

.result-image {
  flex-shrink: 0;
  width: 150px;
  height: 90px;
  border-radius: 6px;
  overflow: hidden;
  background: rgba(99, 102, 241, 0.1);
}

.result-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.result-image.speaking {
  position: relative;
}
.result-image.speaking::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(90deg,
    #00ff00 0%,    /* Bright green */
    #ff00ff 33%,   /* Bright magenta */
    #00ffff 66%,   /* Bright cyan */
    #ff00ff 100%   /* Bright magenta */
  );
  background-size: 200% 100%;
  animation:
    waveform 1s ease-in-out infinite,
    gradientSlide 1.5s linear infinite;
  border-radius: 0 0 6px 6px;
  opacity: 1;
  /* Add dual-color glow effect */
  box-shadow:
    0 0 15px rgba(0, 255, 0, 0.5),
    0 0 25px rgba(255, 0, 255, 0.4);
}

/* Mini thumbnail with matching colors */
.mini-thumbnail.speaking::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg,
    #00ff00 0%,    /* Bright green */
    #ff00ff 33%,   /* Bright magenta */
    #00ffff 66%,   /* Bright cyan */
    #ff00ff 100%   /* Bright magenta */
  );
  background-size: 200% 100%;
  animation:
    waveform 1s ease-in-out infinite,
    gradientSlide 1.5s linear infinite;
  opacity: 1;
  box-shadow:
    0 0 15px rgba(0, 255, 0, 0.5),
    0 0 25px rgba(255, 0, 255, 0.4);
}

@keyframes waveform {
  0%, 100% { transform: scaleY(0.8); }
  50% { transform: scaleY(2.5); }
}

@keyframes gradientSlide {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}

.placeholder-svg {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.result-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.result-date {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.5);
}

/* Update existing styles */
.result-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.result-title {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 768px) {
  .mini-thumbnail {
    width: 24px;
    height: 24px;
  }
}

.mini-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mini-thumbnail-placeholder {
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(4, 255, 134, 0.1), rgba(4, 255, 134, 0.2));
}

/* Update meta-info to accommodate thumbnails */
.meta-info {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  border-left: 1px solid rgba(99, 102, 241, 0.1);
}

.mini-thumbnail {
  width: 80px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
  background: rgba(4, 255, 134, 0.1);
  position: relative;
  margin-left: 0; /* Remove overlapping effect */
}

.mini-thumbnail.auto-scrolling-layout.is-rag-sources-view {
  width: 80px;
  height: 80px;
}


.thumbnail-more {
  font-size: 0.75rem;
  background: rgba(4, 255, 134, 0.2);
  padding: 4px 8px;
  border-radius: 8px;
  color: #04ff86;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 4;
  grid-row: 2;
}

/* Mobile Responsive Design */
@media screen and (max-width: 768px) {
  .thumbnail-grid  {
    grid-template-columns: repeat(5, auto);
    grid-template-rows: repeat(4, auto);
    max-width: 100%;
    gap: 3px;
  }

  .mini-thumbnail, .mini-thumbnail.auto-scrolling-layout, .mini-thumbnail.auto-scrolling-layout.is-rag-sources-view  {
    width: 56px;
    height: 56px;
    border-radius: 4px;
  }

  .thumbnail-more {
    grid-column: 3;
    grid-row: 2;
    font-size: 0.7rem;
    padding: 2px 6px;
  }
}

/* Extra small devices */
@media screen and (max-width: 480px) {
  .thumbnail-grid  {
    grid-template-columns: repeat(5, auto);
    grid-template-rows: repeat(4, auto);
    max-width: 100%;
    gap: 2px;
  }

  .mini-thumbnail {
    width: 56px;
    height: 56px;
  }

  .thumbnail-more {
    grid-column: 2;
    grid-row: 2;
  }
}

/* Update the meta-info to accommodate the new thumbnail grid */
.meta-info {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 12px;
  border-left: 1px solid rgba(99, 102, 241, 0.1);
}
</style>
