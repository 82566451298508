<template>
  <div class="citation-renderer" ref="container">
    <div v-html="processedHtml" @click="handleClick"></div>
    <transition name="fade">
      <div v-if="activeTooltip" class="modal-overlay" @click="closeTooltip"></div>
    </transition>
    <transition name="slide">
      <div v-if="activeTooltip"
           class="tooltip-modal"
           :style="tooltipStyle"
           v-html="activeTooltip.content">
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import API from "@/api"

export default defineComponent({
  name: 'CitationRenderer',
  props: {
    chatId: {
      type: String,
      default: null
    },
    isSharedView: {
      type: Boolean,
      default: false
    },
    html: {
      type: String,
      required: true
    },
    voiceSettings: {
      type: Object,
      default: () => ({
        enabled: false,
        voice: null,
        rate: 1.1
      })
    },
    isRagSourcesView: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isGeneratingBackground: false,
      allAudioResponses: [], // Store all audio responses
      audioBuffers: [], // Store individual audio buffers
      isGeneratingFullAudio: false,
      audioContext: null,
      audioPlayer: null,
      isPlaying: false,
      currentAudioBuffer: null,
      isReading: false,
      formattedHtml: '',
      activeTooltip: null,
      isMobile: false,
      autoScrollInterval: null,
      currentCitationIndex: 0,
      citations: [],
      isPaused: false,
      lastPosition: 0,
      processedHtml: '',
    };
  },

  computed: {
    isSafari() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.includes('safari') && !ua.includes('chrome');
    },
    isFirefox() {
      return navigator.userAgent.toLowerCase().includes('firefox');
    },
    isEdge() {
      return navigator.userAgent.toLowerCase().includes('edg');
    },
    tooltipStyle() {
      if (this.isMobile) {
        return {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          maxHeight: '60vh'
        }
      }

      if (!this.activeTooltip) return {}

      const citation = this.activeTooltip.element.getBoundingClientRect()
      const container = this.$refs.container.getBoundingClientRect()
      const windowWidth = window.innerWidth
      const tooltipWidth = 250

      // Position to the right by default
      let left = `${citation.right + 10}px`

      // If tooltip would overflow right edge, position to the left
      if (citation.right + tooltipWidth + 10 > windowWidth) {
        left = `${citation.left - tooltipWidth - 10}px`
      }

      return {
        position: 'fixed',
        top: `${citation.top}px`,
        left,
        maxHeight: '60vh',
        overflowY: 'auto'
      }
    },

  },

  watch: {
    html: {
      immediate: true,
      handler(newHtml) {
        if (!newHtml) return;
        this.formattedHtml = this.processLargeCitationGroups(newHtml);
        this.processHtml(newHtml);
      }
    }
  },

  mounted() {
    if (this.isMobile) {
      this.$refs.container.addEventListener('touchstart', this.handleMobileTouch);
    }
    this.initializeVoices();

    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);

    // Add hover event handler that triggers the same behavior as click
    this.$refs.container.addEventListener('mouseover', (event) => {
      if (this.isMobile) return; // Keep click-only for mobile

      const citationLink = event.target.closest('.citation-link');
      if (citationLink) {
        // Simply call the existing click handler
        this.handleCitationClick(event);
      }
    });

    // Optional: Clean up highlights when hover ends
    this.$refs.container.addEventListener('mouseout', (event) => {
      if (this.isMobile) return;

      const citationLink = event.target.closest('.citation-link');
      if (citationLink) {
        this.removeHighlights();
        citationLink.classList.remove('citation-link-active');
      }
    });

    // Set up hover handlers for desktop
    if (this.$refs.container) {
      this.$refs.container.addEventListener('mouseover', this.handleHover);
      this.$refs.container.addEventListener('mouseout', this.handleMouseOut);
    }
  },

  beforeDestroy() {
    this.stopSpeech();
    window.speechSynthesis.cancel();
    this.clearSpeechTimer();
    this.cleanup();
    this.stopAutoScroll();
    window.removeEventListener('resize', this.checkMobile);
    // Clean up event listeners if needed
    window.removeEventListener('resize', this.checkMobile);
    if (this.$refs.container) {
      this.$refs.container.removeEventListener('mouseover', this.handleHover);
      this.$refs.container.removeEventListener('mouseout', this.handleMouseOut);
    }
    if (this.audioPlayer) {
      this.audioPlayer.pause();
      this.audioPlayer = null;
    }
  },

  methods: {
    updateVoiceSettings(settings) {
      if (this.isPlaying) {
        this.stopSpeech();
        // If currently playing, restart with new settings
        this.speakText(this.citations[this.currentCitationIndex].dataset.sourceText);
      }
    },
    handleMobileTouch(event) {
      if (!this.isReading || this.isPaused) return;

      const touch = event.touches[0];
      const element = document.elementFromPoint(touch.clientX, touch.clientY);
      const citationLink = element?.closest('.citation-link');

      if (citationLink) {
        const clickedIndex = this.citations.findIndex(c => c === citationLink);
        this.currentCitationIndex = clickedIndex;
        this.lastPosition = clickedIndex;

        window.speechSynthesis.cancel();
        this.clearSpeechTimer();
        if (this.autoScrollInterval) {
          clearInterval(this.autoScrollInterval);
        }

        this.triggerCitation(citationLink);
        this.startAutoScrollFromCurrent();
      }
    },
    async concatenateAudioBuffers(buffers) {
      // Calculate total duration
      const totalLength = buffers.reduce((sum, buffer) => sum + buffer.length, 0);

      // Create new buffer for combined audio
      const combinedBuffer = this.audioContext.createBuffer(
        buffers[0].numberOfChannels,
        totalLength,
        buffers[0].sampleRate
      );

      // Combine all buffers
      let offset = 0;
      for (const buffer of buffers) {
        for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
          const channelData = buffer.getChannelData(channel);
          combinedBuffer.getChannelData(channel).set(channelData, offset);
        }
        offset += buffer.length;
      }

      return combinedBuffer;
    },

    async audioBufferToBlob(audioBuffer) {
      // Create offline context for rendering
      const offlineContext = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        audioBuffer.length,
        audioBuffer.sampleRate
      );

      // Create buffer source
      const source = offlineContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(offlineContext.destination);
      source.start();

      // Render audio
      const renderedBuffer = await offlineContext.startRendering();

      // Convert to WAV format
      const wavData = this.audioBufferToWav(renderedBuffer);
      return new Blob([wavData], { type: 'audio/wav' });
    },

    audioBufferToWav(buffer) {
      // Implementation of audioBufferToWav function
      // This converts AudioBuffer to WAV format
      // You can use existing libraries like audioBufferToWav or implement your own
      // For brevity, actual implementation is omitted here
    },

    async toggleAutoScroll(shouldPlay, delay = 10000) {
      if (shouldPlay) {
        // Initialize audio context on user interaction
        this.startAutoScroll(delay);
      } else {
        this.stopAudioPlayback();
        this.pauseAutoScroll();
      }
    },

    cleanup() {
      // Clean up audio resources
      this.audioBuffers = [];
      if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
      }
    },

    stopAudioPlayback() {
      window.speechSynthesis.cancel();
      this.clearSpeechTimer();
      this.isPlaying = false;
    },

    startSpeechTimer() {
      // Clear any existing timer
      if (this.speechTimer) {
        clearTimeout(this.speechTimer);
      }

      // Create new timer that keeps speech alive
      const keepAlive = () => {
        if (this.isPlaying) {
          window.speechSynthesis.pause();
          window.speechSynthesis.resume();
          this.speechTimer = setTimeout(keepAlive, 10000);
        }
      };

      this.speechTimer = setTimeout(keepAlive, 10000);
    },

    clearSpeechTimer() {
      if (this.speechTimer) {
        clearTimeout(this.speechTimer);
        this.speechTimer = null;
      }
    },

    stopSpeech() {
      window.speechSynthesis.cancel();
      this.clearSpeechTimer();
      this.isPlaying = false;
    },

    handleHover(event) {
      if (this.isMobile || !event.target) return;

      const citationLink = event.target.closest('.citation-link');
      if (!citationLink || !this.isReading || this.isPaused) return;

      const clickedIndex = this.citations.findIndex(c => c === citationLink);
      this.currentCitationIndex = clickedIndex;
      this.lastPosition = clickedIndex;

      window.speechSynthesis.cancel();
      this.clearSpeechTimer();
      if (this.autoScrollInterval) {
        clearInterval(this.autoScrollInterval);
      }

      this.triggerCitation(citationLink);
      this.startAutoScrollFromCurrent();
    },

    handleMouseOut(event) {
      if (this.isMobile) return;

      const citationLink = event.target.closest('.citation-link');
      if (citationLink) {
        this.removeHighlights();
        citationLink.classList.remove('citation-link-active');
      }
    },
    async speakText(text) {
      if (!this.voiceSettings.enabled || !text) return;

      // Extract source URL if present
      const sourceMatch = text.match(/\[(?:Source|URL):\s*(https?:\/\/[^\]]+)\]/);
      const sourceUrl = sourceMatch ? sourceMatch[1] : null;

      try {
        // Stop any current speech
        if (this.isPlaying) {
          this.stopSpeech();
        }

        // Format the text for speech
        text = this.formatTextForSpeech(text);

        // Emit the speaking source URL
        if (sourceUrl) {
          this.$emit('citation-speaking', { url: sourceUrl });
        }

        // Create and configure speech utterance
        const utterance = new SpeechSynthesisUtterance(text);

        // Configure voice settings
        const voices = window.speechSynthesis.getVoices();
        let selectedVoice;

        // First check Edge regardless of platform. Edge good quality voices not available on mobile
        if (this.isEdge && !this.isMobile) {
          // For Edge (desktop or mobile), prioritize the best Microsoft neural voices
          const bestMicrosoftVoices = voices.filter(voice =>
            voice.lang.startsWith('en') &&
            voice.name.includes('Microsoft') &&
            (
              // Indian English voices
              voice.name.includes('Neerja') ||    // Female Indian English
              // South African English
              voice.name.includes('Leah')   ||      // Female South African English
              // US/UK English voices
              voice.name.includes('Christopher') ||
              voice.name.includes('Jenny') ||
              voice.name.includes('Guy')
            )
          );

          if (bestMicrosoftVoices.length > 0) {
            selectedVoice = bestMicrosoftVoices[Math.floor(Math.random() * bestMicrosoftVoices.length)];
          } else {
            // Fallback to any Microsoft English voice if the best ones aren't available
            const microsoftVoices = voices.filter(voice =>
              voice.lang.startsWith('en') &&
              voice.name.includes('Microsoft')
            );

            if (microsoftVoices.length > 0) {
              selectedVoice = microsoftVoices[Math.floor(Math.random() * microsoftVoices.length)];
            }
          }
        } else if (this.isMobile || this.isSafari || this.isFirefox) {
          // For mobile (non-Edge), Safari, and Firefox - use curated tested voices
          const testedVoices = [
            "Google US English",
            "Karen",
            "Samantha",
            "Rishi",
            "Moira",
            "Tessa",
            "Alex",
            "Victoria",
            "Daniel",
            "Catherine"
          ];

          const availableTestedVoices = voices.filter(voice =>
            testedVoices.includes(voice.name)
          );

          if (availableTestedVoices.length > 0) {
            selectedVoice = availableTestedVoices[Math.floor(Math.random() * availableTestedVoices.length)];
          }
        } else {
          // For Chrome, use Google voices
          const googleVoices = voices.filter(voice =>
            voice.lang.startsWith('en') &&
            voice.name.includes('Google')
          );

          if (googleVoices.length > 0) {
            selectedVoice = googleVoices[Math.floor(Math.random() * googleVoices.length)];
          }
        }

        // Set selected voice if available
        if (selectedVoice) {
          utterance.voice = selectedVoice;
        }

        // Set speech properties. For mobile set rate to 0.9, for web set rate to 1.1
        utterance.rate = this.voiceSettings.rate;
        utterance.pitch = 1.0;
        utterance.volume = 1.0;

        // Handle speech events
        utterance.onstart = () => {
          this.isPlaying = true;
          this.startSpeechTimer(); // Start the keepAlive timer when speech starts
        };

        utterance.onend = () => {
          this.isPlaying = false;
          if (sourceUrl) {
            this.$emit('citation-complete', { url: sourceUrl });
          }

          // move to next citation when speech completes
          if (this.isReading && !this.isPaused) {
            this.currentCitationIndex++;
            this.lastPosition = this.currentCitationIndex;

            if (this.currentCitationIndex >= this.citations.length) {
              this.stopAutoScroll();
              this.$emit('auto-scroll-complete');
              return;
            }

            this.triggerCitation(this.citations[this.currentCitationIndex]);
          }
        };

        utterance.onerror = (event) => {
          this.isPlaying = false;
          this.clearSpeechTimer(); // Clear the timer on error
          if (sourceUrl) {
            this.$emit('citation-complete', { url: sourceUrl });
          }
        };

        // Start speaking
        window.speechSynthesis.speak(utterance);

      } catch (error) {
        this.isPlaying = false;
        this.clearSpeechTimer(); // Clear the timer on error
        if (sourceUrl) {
          this.$emit('citation-complete', { url: sourceUrl });
        }
      }
    },
    formatTextForSpeech(text) {
      // Function to extract domain from URL
      const getDomain = (url) => {
        try {
          return new URL(url).hostname.replace('www.', '');
        } catch (e) {
          console.warn('Error parsing URL:', e);
          return url;
        }
      };

      // First check if this is an error message
      const errorPattern = /Failed to (?:extract text from|fetch) (.+?)(?:: .*)?$/;
      const errorMatch = text.match(errorPattern);

      if (errorMatch) {
        const domain = getDomain(errorMatch[1]);
        return `Failed to load content from ${domain}`;
      }

      // For non-error messages, process URLs normally
      // Extract all URLs first
      const urlMatches = [
        ...text.matchAll(/\[URL:\s*(https?:\/\/[^\]]+)\]/g),
        ...text.matchAll(/\[Source:\s*(https?:\/\/[^\]]+)\]/g)
      ];

      // Get unique domains
      const uniqueDomains = [...new Set(
        urlMatches
          .map(match => getDomain(match[1]))
      )];

      // Replace all URL and Source patterns with a single source attribution at the end
      let cleanText = text
        .replace(/\[URL:\s*(https?:\/\/[^\]]+)\]/g, '')
        .replace(/\[Source:\s*(https?:\/\/[^\]]+)\]/g, '')
        .trim();

      // Add unique sources at the end if there are any
      if (uniqueDomains.length > 0) {
        cleanText += ` source: ${uniqueDomains.join(', ')}`;
      }

      return cleanText;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768
    },

    processHtml(html) {
      const processMatches = (content) => {
        // Extract all HREF matches to create set of terms to remove
        const hrefMatches = [...content.matchAll(/\[HREF:\s*([^|]+)\|(https?:\/\/[^\]]+)\]/g)];
        const termsToRemove = new Set(hrefMatches.map(match => match[1]));

        // Remove duplicated terms from end
        termsToRemove.forEach(term => {
          const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          content = content.replace(new RegExp(`\\s*${escapedTerm}\\s*$`), '');
        });

        // Replace HREF syntax
        return content.replace(/\[HREF:\s*([^|]+)\|https?:\/\/[^\]]+\]/g, '');
      };

      html = processMatches(html);

      // Create a temporary div to manipulate the HTML
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      // Process all citation links
      const citations = tempDiv.querySelectorAll('.citation-link');
      citations.forEach(citation => {
        if (citation.dataset.sourceText) {
          // Process the source text
          citation.dataset.sourceText = processMatches(citation.dataset.sourceText.trim());

          // Remove any existing v-tooltip directives
          citation.removeAttribute('v-tooltip');

          // Add scrollable tooltip class
          citation.classList.add('scrollable-tooltip');
        }
      });

      // Get the processed HTML
      this.processedHtml = tempDiv.innerHTML;
    },
    handleClick(event) {
      // if not mobile, trigger handleCitationClick
      if (!this.isMobile) {
        this.handleCitationClick(event)
        return
      }
      const citation = event.target.closest('.citation-link')
      if (!citation) return

      event.stopPropagation()

      // Toggle no-hover class for all citation links
      document.querySelectorAll('.citation-link').forEach(link => {
        link.classList.add('no-hover')
      })

      if (this.activeTooltip?.element === citation) {
        this.closeTooltip()
        return
      }

      this.activeTooltip = {
        element: citation,
        content: citation.dataset.sourceText
      }
      if (citation.dataset.tooltipHeader) {
        this.activeTooltip.content = citation.dataset.tooltipHeader + this.activeTooltip.content;
      }
    },

    closeTooltip() {
      // Remove no-hover class from all citation links
      document.querySelectorAll('.citation-link').forEach(link => {
        link.classList.remove('no-hover')
      })
      this.activeTooltip = null
    },

    safeDecodeSourceText(text) {
        if (!text) return '';

        try {
            // First try direct decodeURIComponent
            return decodeURIComponent(text);
        } catch (uriError) {
            try {
                // If that fails, try to clean up the text first
                const cleaned = text
                    .replace(/%(?![0-9A-Fa-f]{2})/g, '%25') // Fix incomplete percent encoding
                    .replace(/[^ -~]/g, encodeURIComponent) // Re-encode non-ASCII using printable ASCII range
                    .replace(/\+/g, ' '); // Handle plus signs

                return decodeURIComponent(cleaned);
            } catch (fallbackError) {
                // If all decoding fails, return the original text
                console.warn('Failed to decode source text:', text, fallbackError);
                return text;
            }
        }
    },
    processLargeCitationGroups(html) {
      if (!html) return '';

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      // First pass: Process each citation link to show metadata in tooltip
      const citations = tempDiv.querySelectorAll('.citation-link');
      citations.forEach(citation => {
          try {
              // Decode and sanitize metadata before parsing
              const rawMetadata = citation.dataset.metadata || '{}';
              const cleanMetadata = this.sanitizeJsonString(rawMetadata);
              const metadata = JSON.parse(cleanMetadata);

              // Create formatted metadata header for display only
              const metadataHeader = this.formatMetadataHeader(metadata);
              citation.dataset.tooltipHeader = metadataHeader;

              // Store back sanitized metadata
              citation.dataset.metadata = JSON.stringify(metadata);

              // Safely decode source text if present
              if (citation.dataset.sourceText) {
                  citation.dataset.sourceText = this.safeDecodeSourceText(citation.dataset.sourceText);
              }
          } catch (error) {
              console.warn('Error processing citation metadata:', error, 'Raw metadata:', citation.dataset.metadata);
              // Set fallback metadata to prevent future errors
              citation.dataset.metadata = '{}';
          }
      });

      // Second pass: Find all citation groups (consecutive citation-link spans)
      const spans = Array.from(tempDiv.querySelectorAll('.citation-link'));

      let currentIndex = 0;
      while (currentIndex < spans.length) {
        try {
          let groupEnd = currentIndex;

          while (groupEnd + 1 < spans.length &&
                this.areSpansAdjacent(spans[groupEnd], spans[groupEnd + 1])) {
            groupEnd++;
          }

          const groupSize = groupEnd - currentIndex + 1;
          if (groupSize > 4) {
            this.createCollapsibleGroup(
              spans.slice(currentIndex, currentIndex + 4),
              spans.slice(currentIndex + 4, groupEnd + 1),
              tempDiv
            );
          }

          currentIndex = groupEnd + 1;
        } catch (error) {
          console.warn('Error processing citation groups:', error);
          currentIndex++;
        }
      }
      return tempDiv.innerHTML;
    },

    sanitizeJsonString(jsonString) {
      try {
          // Remove any HTML tags.
          const withoutTags = jsonString.replace(/<[^>]*>/g, '');

          // Decode HTML entities
          const decoded = this.decodeHtmlEntities(withoutTags);

          // Fix any double-encoded quotes
          const fixedQuotes = decoded.replace(/&quot;/g, '"')
                                  .replace(/\\"/g, '"')
                                  .replace(/""/, '"');

          // Ensure it's valid JSON structure
          if (!fixedQuotes.startsWith('{')) {
              return '{}';
          }

          return fixedQuotes;
      } catch (error) {
          console.warn('Error sanitizing JSON string:', error);
          return '{}';
      }
    },

    decodeHtmlEntities(str) {
      const txt = document.createElement('textarea');
      txt.innerHTML = str;
      return txt.value;
    },

    formatMetadataHeader(metadata) {
      if (!metadata || Object.keys(metadata).length === 0) return '';

      let header = '╭──────────── Source Info ────────────╮\n';

      if (metadata.type) {
        header += `Type: ${this.capitalizeFirst(metadata.type)}\n`;
      }

      if (metadata.title) {
        header += `Title: ${metadata.title}\n`;
      }

      if (metadata.fileName) {
        header += `File: ${metadata.fileName}\n`;
      }

      if (metadata.date) {
        header += `Date: ${new Date(metadata.date).toLocaleDateString()}\n`;
      }

      if (metadata.url) {
        header += `URL: ${metadata.url}\n`;
      }

      header += '╰─────────────────────────────────────╯\n\n';

      return header;
    },

    capitalizeFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    areSpansAdjacent(span1, span2) {
      // Check if there's only whitespace or commas between spans
      let node = span1.nextSibling;
      while (node && node !== span2) {
        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent.trim();
          if (text && text !== ',') return false;
        } else if (node.nodeType === Node.ELEMENT_NODE && !node.classList.contains('citation-link')) {
          return false;
        }
        node = node.nextSibling;
      }
      return true;
    },

    createCollapsibleGroup(visibleSpans, hiddenSpans, container) {
      if (!hiddenSpans.length) return;

      const groupId = Math.random().toString(36).substr(2, 9);

      // Create button container
      const buttonContainer = document.createElement('span');
      buttonContainer.className = 'citation-button-container';

      // Create expand/collapse buttons...
      const expandButton = document.createElement('span');
      expandButton.className = 'citation-expand';
      expandButton.dataset.group = groupId;
      expandButton.setAttribute('role', 'button');
      expandButton.setAttribute('tabindex', '0');
      expandButton.textContent = '...';

      const collapseButton = document.createElement('span');
      collapseButton.className = 'citation-collapse';
      collapseButton.dataset.group = groupId;
      collapseButton.setAttribute('role', 'button');
      collapseButton.setAttribute('tabindex', '0');
      collapseButton.style.display = 'none';
      collapseButton.textContent = '←';

      buttonContainer.appendChild(expandButton);
      buttonContainer.appendChild(collapseButton);

      // Create hidden group
      const hiddenGroup = document.createElement('span');
      hiddenGroup.className = 'citation-group-hidden';
      hiddenGroup.dataset.group = groupId;
      hiddenGroup.style.display = 'none';

      // Process spans with safe decoding
      const processSpan = (span) => {
        try {
          let sourceText = span.dataset.sourceText;
          if (sourceText) {
            span.dataset.sourceText = this.safeDecodeSourceText(sourceText).trim();
          }
          return span.cloneNode(true);
        } catch (error) {
          console.warn('Error processing span:', error);
          return span.cloneNode(true);
        }
      };

       // Add spans to hidden group
       hiddenSpans.forEach(span => {
        try {
            hiddenGroup.appendChild(processSpan(span));
        } catch (error) {
            console.warn('Error appending span:', error);
        }
      });

      // Insert elements
      const lastVisibleSpan = visibleSpans[visibleSpans.length - 1];
      lastVisibleSpan.after(buttonContainer, hiddenGroup);

      // Remove original hidden spans
      hiddenSpans.forEach(span => {
        try {
            span.remove();
        } catch (error) {
            console.warn('Error removing span:', error);
        }
      });
    },
    handleExpandCollapse(target) {
      const groupId = target.dataset.group;
      const hiddenGroup = document.querySelector(`.citation-group-hidden[data-group="${groupId}"]`);
      const buttonContainer = target.closest('.citation-button-container');
      const expandButton = buttonContainer.querySelector('.citation-expand');
      const collapseButton = buttonContainer.querySelector('.citation-collapse');

      if (target.classList.contains('citation-expand')) {
        if (hiddenGroup && collapseButton) {
          expandButton.style.display = 'none';
          hiddenGroup.style.display = 'inline';
          collapseButton.style.display = 'inline-flex';
        }
      } else if (target.classList.contains('citation-collapse')) {
        if (hiddenGroup && expandButton) {
          collapseButton.style.display = 'none';
          hiddenGroup.style.display = 'none';
          expandButton.style.display = 'inline-flex';
        }
      }
    },

    handleCitationClick(event, isInternal = false) {
      if (event && typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }

      const target = event.target;

      // Handle expand/collapse buttons
      if (target.classList.contains('citation-expand') || target.classList.contains('citation-collapse')) {
        this.handleExpandCollapse(target);
        return;
      }

      const citationLink = target.closest('.citation-link');
      if (!citationLink) return;

      // Mobile handling
      if (!this.isMobile) {
        citationLink.classList.remove('no-hover');
      } else {
        document.querySelectorAll('.citation-link').forEach(link => {
          link.classList.add('no-hover');
        });

        if (this.activeTooltip?.element === citationLink) {
          this.closeTooltip();
          return;
        }
      }

      // Find index of clicked citation and handle auto-scroll
      const clickedIndex = this.citations.findIndex(c => c === citationLink);
      if (!isInternal && this.isReading && !this.isPaused) {
        this.currentCitationIndex = clickedIndex;
        this.lastPosition = clickedIndex;

        // Stop current audio and clear timers
        window.speechSynthesis.cancel();
        this.clearSpeechTimer();
        if (this.autoScrollInterval) {
          clearInterval(this.autoScrollInterval);
        }

        // Resume from new position
        this.triggerCitation(citationLink);
        this.startAutoScrollFromCurrent();
      }

      try {
        const citation = citationLink.dataset.citation;

        // Process source text
        let sourceText = '';
        try {
          const rawSourceText = citationLink.dataset.sourceText || '';
          sourceText = this.decodeHtmlEntities(decodeURIComponent(rawSourceText));
        } catch (decodeError) {
          sourceText = citationLink.dataset.sourceText || '';
        }

        // Process metadata
        let metadata = {};
        try {
          const cleanMetadata = this.sanitizeJsonString(citationLink.dataset.metadata || '{}');
          metadata = JSON.parse(cleanMetadata);
        } catch (metadataError) {
          console.warn('Error parsing metadata:', metadataError);
        }

        // Update UI
        this.removeHighlights();
        citationLink.classList.add('citation-link-active');

        // Set active object if metadata exists
        if (metadata && metadata.type && metadata.id) {
          this.$store.dispatch('websocket/setActiveObject', {
            type: this.getObjectType(metadata.type),
            id: metadata.id,
            rag_strategy: 'llm_passthrough',
            chatId: this.chatId,
            isSharedView: this.isSharedView,
          });
        }

        // Highlight text in RAG if available
        if (sourceText) {
          this.highlightInRag(sourceText);
        }

        // Emit citation click event
        this.$emit('citation-click', {
          citation,
          element: citationLink,
          sourceText,
          metadata
        });

      } catch (error) {
        console.error('Error handling citation click:', error);
      }
    },

    getObjectType(type) {
      const typeMap = {
          'file': 'FileSubmission',
          'text': 'TextSubmission',
          'youtube': 'YouTubeTranscriptSubmission',
          'search': 'ChatMessage',
          'ecrag': 'ChatMessage'
      };
      return typeMap[type] || 'ChatMessage';
    },

    // Update the highlightInRag method for smoother scrolling
    highlightInRag(text) {
      const ragContainer = document.querySelector('.rag-container');
      if (!ragContainer) return;

      const walker = document.createTreeWalker(
        ragContainer,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );

      let node;
      const highlights = [];

      // Find all matching text nodes first
      while ((node = walker.nextNode())) {
        if (node.textContent.includes(text)) {
          const range = document.createRange();
          range.setStart(node, node.textContent.indexOf(text));
          range.setEnd(node, node.textContent.indexOf(text) + text.length);

          const highlight = document.createElement('span');
          highlight.className = 'citation-highlight';
          range.surroundContents(highlight);
          highlights.push(highlight);
        }
      }

      // If we found highlights, scroll to the first one
      if (highlights.length > 0) {
        if (this.isMobile || !this.isRagSourcesView) {
          highlights[0].scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
    },

    removeHighlights() {
      document.querySelectorAll('.citation-link-active')
        .forEach(el => el.classList.remove('citation-link-active'));

      document.querySelectorAll('.citation-highlight')
        .forEach(el => {
          const parent = el.parentNode;
          parent.replaceChild(document.createTextNode(el.textContent), el);
          parent.normalize();
        });
    },

    moveToNext() {
      if (this.currentCitationIndex < this.citations.length - 1) {
        // Cancel current speech and clear any existing intervals/timeouts
        window.speechSynthesis.cancel();
        this.clearSpeechTimer();
        if (this.autoScrollInterval) {
          clearInterval(this.autoScrollInterval);
        }

        this.currentCitationIndex++;
        this.lastPosition = this.currentCitationIndex;
        this.triggerCitation(this.citations[this.currentCitationIndex]);

        // If we were auto-scrolling, restart from new position
        if (!this.isPaused) {
          this.startAutoScrollFromCurrent();
        }
      }
    },

    moveToPrevious() {
      if (this.currentCitationIndex > 0) {
        // Cancel current speech and clear any existing intervals/timeouts
        window.speechSynthesis.cancel();
        this.clearSpeechTimer();
        if (this.autoScrollInterval) {
          clearInterval(this.autoScrollInterval);
        }

        this.currentCitationIndex--;
        this.lastPosition = this.currentCitationIndex;
        this.triggerCitation(this.citations[this.currentCitationIndex]);

        // If we were auto-scrolling, restart from new position
        if (!this.isPaused) {
          this.startAutoScrollFromCurrent();
        }
      }
    },

    // Updated helper method to restart auto-scroll from current position
    async startAutoScrollFromCurrent() {
      const runAutoScroll = async () => {
        if (!this.isPlaying) return;

        // Create a promise for speech completion
        const speechPromise = new Promise(resolve => {
          const checkSpeechEnd = setInterval(() => {
            if (!window.speechSynthesis.speaking) {
              clearInterval(checkSpeechEnd);
              resolve();
            }
          }, 100);
        });

        // Create a promise for the minimum delay
        const delayPromise = new Promise(resolve => setTimeout(resolve, this.delay));

        // Wait for both voice completion and minimum delay
        await Promise.all([speechPromise, delayPromise]);

        // If still playing, move to next citation
        if (this.isPlaying) {
          this.currentCitationIndex++;
          this.lastPosition = this.currentCitationIndex;

          if (this.currentCitationIndex >= this.citations.length) {
            this.stopAutoScroll();
            this.$emit('auto-scroll-complete');
            return;
          }

          await this.triggerCitation(this.citations[this.currentCitationIndex]);
          // Continue the cycle
          runAutoScroll();
        }
      };

      // Start the auto-scroll cycle
      runAutoScroll();
    },

    pauseAutoScroll() {
      if (this.autoScrollInterval) {
        clearInterval(this.autoScrollInterval);
        this.autoScrollInterval = null;
      }
      this.isPaused = true;
      this.lastPosition = this.currentCitationIndex;
      this.isReading = false;

      // Add this line to stop any playing audio
      this.stopAudioPlayback();
    },

    stopAutoScroll() {
      if (this.autoScrollInterval) {
        clearInterval(this.autoScrollInterval);
        this.autoScrollInterval = null;
      }
      this.currentCitationIndex = 0;
      this.lastPosition = 0;
      this.isPaused = false;
      this.isReading = false;

      // stop any playing audio
      this.stopAudioPlayback();

      this.closeTooltip();
      this.$emit('auto-scroll-complete');
    },

    // Add touch handling methods
    setupTouchHandlers(element) {
      let touchStartX = 0;
      let touchStartTime = 0;

      element.addEventListener('touchstart', (e) => {
        touchStartX = e.touches[0].clientX;
        touchStartTime = Date.now();
      }, { passive: true });

      element.addEventListener('touchend', (e) => {
        const touchEndX = e.changedTouches[0].clientX;
        const touchEndTime = Date.now();
        const touchDuration = touchEndTime - touchStartTime;
        const swipeDistance = touchEndX - touchStartX;

        // Only trigger if it's a quick swipe (less than 300ms) and significant distance (>50px)
        if (touchDuration < 300 && Math.abs(swipeDistance) > 50) {
          if (swipeDistance > 0) {
            this.moveToPrevious();
          } else {
            this.moveToNext();
          }
          e.preventDefault();
        }
      }, { passive: false });
    },

    async startAutoScroll(delay = 10000) {
      // Find the active tab pane that contains this citation renderer
      const activeTabPane = this.$el.closest('.tab-pane');

      // Get citations only from the active tab pane if it exists, otherwise fallback to current element
      const containerElement = activeTabPane || this.$el;
      const allCitations = Array.from(containerElement.querySelectorAll('.citation-link'));

      if (!allCitations.length) {
        // If no citations found, stop auto-scrolling
        this.stopAutoScroll();
        this.$emit('auto-scroll-complete');
        return;
      }

      // Filter unique citations
      this.citations = allCitations.filter((citation, index, self) =>
        index === self.findIndex((c) => (
          (c.dataset.sourceText && citation.dataset.sourceText &&
          c.dataset.sourceText === citation.dataset.sourceText) ||
          (!c.dataset.sourceText && c.innerText === citation.innerText)
        ))
      );

      if (this.autoScrollInterval) {
        clearInterval(this.autoScrollInterval);
      }

      this.delay = delay;
      this.currentCitationIndex = this.isPaused ? this.lastPosition : 0;
      this.isPaused = false;
      this.isReading = true;

      // Emit initial total
      this.$emit('auto-scroll-progress', {
        currentIndex: this.currentCitationIndex,
        total: this.citations.length
      });

      const moveToNextCitation = async () => {
        this.currentCitationIndex++;
        this.lastPosition = this.currentCitationIndex;

        if (this.currentCitationIndex >= this.citations.length) {
          this.stopAutoScroll();
          this.$emit('auto-scroll-complete');
          return false;
        }

        await this.triggerCitation(this.citations[this.currentCitationIndex]);
        return true;
      };

      // Start with first citation
      await this.triggerCitation(this.citations[this.currentCitationIndex]);

      // Create a promise that resolves after the delay
      const createDelayPromise = () => new Promise(resolve => setTimeout(resolve, this.delay));

      // Set up the auto-scroll interval with voice completion handling
      const runAutoScroll = async () => {
        if (!this.isPlaying) return;

        // Wait for both voice completion and minimum delay
        const delayPromise = createDelayPromise();

        // Create a promise for speech completion
        const speechPromise = new Promise(resolve => {
          const checkSpeechEnd = setInterval(() => {
            if (!window.speechSynthesis.speaking) {
              clearInterval(checkSpeechEnd);
              resolve();
            }
          }, 100);
        });

        // Wait for both promises to complete
        await Promise.all([speechPromise, delayPromise]);

        // If still playing, move to next citation
        if (this.isPlaying) {
          const hasMore = await moveToNextCitation();
          if (hasMore) {
            runAutoScroll(); // Continue the cycle
          }
        }
      };

      // Start the auto-scroll cycle
      runAutoScroll();
    },

    initializeVoices() {
      // Some browsers need a small delay to load voices
      if (window.speechSynthesis.getVoices().length === 0) {
        window.speechSynthesis.addEventListener('voiceschanged', () => {
          this.voices = window.speechSynthesis.getVoices();
        });
      } else {
        this.voices = window.speechSynthesis.getVoices();
      }
    },

    async generateFullAudioInBackground() {
      const allCitations = Array.from(this.$el.querySelectorAll('.citation-link'));
      if (!allCitations.length) return;

      // Filter out duplicates based on source text or content
      this.citations = allCitations.filter((citation, index, self) =>
        index === self.findIndex((c) => (
          (c.dataset.sourceText && citation.dataset.sourceText &&
          c.dataset.sourceText === citation.dataset.sourceText) ||
          (!c.dataset.sourceText && c.innerText === citation.innerText)
        ))
      );

      if (!this.citations?.length || this.isGeneratingBackground) return;

      try {
        this.isGeneratingBackground = true;
        this.allAudioResponses = [];

        // Set maximum size limit (50MB = 50 * 1024 * 1024 bytes)
        const MAX_SIZE_BYTES = 50 * 1024 * 1024;
        let currentSize = 0;

        // Keep track of processed text to avoid duplicates
        const processedTexts = new Set();

        // Count unique citations first
        const uniqueCitations = this.citations.filter(citation => {
          const text = citation.dataset.sourceText || citation.innerText || '';
          const headerText = citation.dataset.tooltipHeader || '';
          const fullText = headerText + text;
          if (processedTexts.has(fullText)) return false;
          processedTexts.add(fullText);
          return true;
        });

        const totalUnique = uniqueCitations.length;
        const duplicatesRemoved = this.citations.length - totalUnique;
        processedTexts.clear(); // Reset for actual processing

        let processedCount = 0;

        // Generate audio for each unique citation
        for (const citation of this.citations) {
          const text = citation.dataset.sourceText || citation.innerText || '';
          const headerText = citation.dataset.tooltipHeader || '';
          const fullText = headerText + text;

          // Skip if we've already processed this text
          if (processedTexts.has(fullText)) continue;
          processedTexts.add(fullText);

          // Format the text for speech
          const formattedText = this.formatTextForSpeech(fullText);

          if (fullText) {
            const [response] = await this.googleTTSClient.synthesizeSpeech(formattedText);

            if (response?.audioContent) {
              // Check if audioContent is already a Uint8Array
              const audioData = response.audioContent instanceof Uint8Array
                ? response.audioContent
                : new Uint8Array(response.audioContent);

              // Check if adding this audio would exceed size limit
              if (currentSize + audioData.length > MAX_SIZE_BYTES) {
                console.warn('Audio size limit reached, stopping further processing');
                // Emit final progress before breaking
                this.$emit('audio-progress', {
                  processed: processedCount,
                  total: totalUnique,
                  duplicatesRemoved,
                  sizeLimit: true,
                  currentSize: Math.round(currentSize / (1024 * 1024)) // Size in MB
                });
                break;
              }

              currentSize += audioData.length;
              this.allAudioResponses.push(audioData);
            }
          }

          processedCount++;
          // Emit progress update
          this.$emit('audio-progress', {
            processed: processedCount,
            total: totalUnique,
            duplicatesRemoved,
            currentSize: Math.round(currentSize / (1024 * 1024)) // Size in MB
          });
        }

        // If we have audio data, emit it
        if (this.allAudioResponses.length > 0) {
          // Combine all audio responses into one Uint8Array
          const totalLength = this.allAudioResponses.reduce((sum, audio) => sum + audio.length, 0);
          const combinedAudio = new Uint8Array(totalLength);

          let offset = 0;
          for (const audio of this.allAudioResponses) {
            combinedAudio.set(audio, offset);
            offset += audio.length;
          }

          // Emit the combined audio data
          this.$emit('full-audio-ready', combinedAudio);
        }
      } catch (error) {
        console.error('Error generating full audio:', error);
        this.$emit('full-audio-ready', null);
      } finally {
        this.isGeneratingBackground = false;
      }
    },

    async triggerCitation(citationElement) {
      if (!citationElement) return;

      this.$emit('auto-scroll-progress', {
        currentIndex: this.currentCitationIndex,
        total: this.citations.length
      });

      this.removeHighlights();

      let textToSpeak = citationElement.dataset.sourceText || citationElement.innerText || '';
      if (citationElement.dataset.tooltipHeader) {
        textToSpeak = citationElement.dataset.tooltipHeader + textToSpeak;
      }

      if (this.isMobile) {
        this.activeTooltip = {
          element: citationElement,
          content: textToSpeak
        };
      } else {
        const syntheticEvent = {
          stopPropagation: () => {},
          target: citationElement
        };
        this.handleCitationClick(syntheticEvent, true); // Pass isInternal flag
      }

      if (this.isMobile || !this.isRagSourcesView) {
        citationElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }

      citationElement.classList.add('citation-link-active');

      if (this.isReading) {
        await this.speakText(textToSpeak);
      }
    },
  }
});
</script>

<style scoped>
:deep(.citation-link) {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.4em;
  height: 1.4em;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: rgba(37, 99, 235, 0.2);
  border-radius: 999px;
  padding: 0 0.4em;
  margin: 0 0.15em;
  text-decoration: none;
  position: relative;
  z-index: 1;
  user-select: none;
  backdrop-filter: blur(4px);
  transition: z-index 0s, all 0.15s ease;
}

/* Raise z-index when hovered */
:deep(.citation-link:hover) {
  z-index: 10002;
  pointer-events: auto !important;
}

:deep(.citation-link::after) {
  content: attr(data-source-text);
  position: absolute;
  max-height: 500px;
  overflow-y: auto !important;
  width: 300px;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-left: 10px;
  /* Modern background with gradient */
  background: linear-gradient(
    145deg,
    rgba(38, 84, 82, 0.98),
    rgba(20, 55, 50, 0.99)
  );
  border: 1px solid rgba(79, 236, 184, 0.25);
  box-shadow:
    0 0 20px rgba(79, 236, 184, 0.08),
    inset 0 0 30px rgba(79, 236, 184, 0.05);
  backdrop-filter: blur(8px);
  color: #f8fafc;
  padding: 1rem 1.25rem;
  border-radius: 0.75rem;
  font-size: 0.75rem;
  white-space: pre-wrap;
  pointer-events: auto !important;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  line-height: 1.4;
  text-align: left;
  -webkit-overflow-scrolling: touch;
}

/* Add subtle highlight effect on hover */
:deep(.citation-link:hover::after) {
  box-shadow:
    0 8px 12px -2px rgba(0, 0, 0, 0.3),
    0 4px 6px -2px rgba(0, 0, 0, 0.2),
    inset 0 1px 0 0 rgba(148, 163, 184, 0.1);
}

/* Show tooltip on hover */
:deep(.citation-link:hover::after) {
  opacity: 1;
  visibility: visible;
  pointer-events: auto !important;
  overflow: auto !important;
}

/* Desktop-only adjustments for right edge */
@media screen and (min-width: 769px) {
  :deep(.citation-link::after) {
    @media (hover: hover) {
      &:hover {
        left: auto;
        right: 100%;
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  :deep(.citation-link::after) {
    position: fixed;
    width: 90vw;
    max-height: 50vh;
    left: 50% !important;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
    max-width: calc(100vw - 32px);
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    z-index: 10001;
  }

  :deep(.citation-link.tooltip-visible::before) {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
  }
}

/* Height adjustments */
@media screen and (max-height: 768px) {
  :deep(.citation-link::after) {
    max-height: 40vh;
  }
}

/* Very small screens */
@media screen and (max-width: 320px) {
  :deep(.citation-link::after) {
    width: calc(100vw - 24px);
    max-height: 60vh;
  }
}

/* Active state */
:deep(.citation-link-active) {
  color: #ffffff;
  background-color: rgba(59, 130, 246, 0.5);
  border-color: rgba(147, 197, 253, 0.5);
  transform: scale(1.25);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  font-weight: 500;
  z-index: 10002;
  touch-action: pan-y pinch-zoom;
}

@media (max-width: 768px) {
  .citation-link-active::after {
    content: '←  Swipe  →';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    opacity: 0.7;
    pointer-events: none;
  }
}

/* Dark theme variation */
:deep([class*='dark'] .citation-link-active) {
  background-color: rgba(59, 130, 246, 0.6);
  border-color: rgba(147, 197, 253, 0.6);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

/* Expand/Collapse buttons */
:deep(.citation-expand),
:deep(.citation-collapse) {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.4em;
  height: 1.4em;
  font-size: 0.75rem;
  font-weight: 500;
  color: #cde0ff;
  background-color: rgba(230, 240, 255, 0.1);
  border-radius: 999px;
  padding: 0 0.4em;
  margin: 0 0.15em;
  transition: all 0.15s ease;
  user-select: none;
}

:deep(.citation-expand:hover),
:deep(.citation-collapse:hover) {
  background-color: rgba(230, 240, 255, 0.2);
  color: #e2edff;
}

/* Hidden citation group */
:deep(.citation-group-hidden) {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease;
}

:deep(.citation-group-hidden[style*="display: inline"]) {
  opacity: 1;
  max-height: none;
}

/* Highlight */
:deep(.citation-highlight) {
  background-color: rgba(205, 224, 255, 0.15);
  border-radius: 4px;
  padding: 2px 0;
  transition: background-color 0.3s ease;
}

/* Scrollbar styling */
:deep(.citation-link::after::-webkit-scrollbar) {
  width: 4px;
}

:deep(.citation-link::after::-webkit-scrollbar-track) {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
}

:deep(.citation-link::after::-webkit-scrollbar-thumb) {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
}

/* Mobile tooltip visibility */
:deep(.citation-link.tooltip-visible) {
  position: relative;
  z-index: 10002;
}

.tooltip-modal {
  position: fixed;
  left: 0% !important;
  top: 0% !important;
  transform: translate(0%, 0%) !important;
  width: 100%;
  max-height: 60vh;
  background: rgba(67, 70, 153, 0.95);
  color: #f8fafc;
  padding: 1rem;
  border-radius: 0.375rem;
  z-index: 10004;
  overflow-y: auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
              0 0 15px rgba(52, 211, 153, 0.15),
              0 0 3px rgba(52, 211, 153, 0.3);
  backdrop-filter: blur(12px);
  border: 1px solid rgba(52, 211, 153, 0.2);
  transition: all 0.3s ease;
}

/* Optional: Add stronger text shadow to help with legibility */
.tooltip-modal .text {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

/* Optimize mobile tooltip positioning */
@media screen and (max-width: 768px) {
  .tooltip-modal {
    width: 90vw;
    max-height: 60vh;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  :deep(.citation-link.no-hover),
  :deep(.citation-link.tooltip-visible) {
    z-index: 1 !important; /* Force lower z-index on mobile */
  }

  /* Ensure modal doesn't interfere with controls */
  .tooltip-modal {
    z-index: 10004;
    pointer-events: auto;
  }

  /* Smoother animations for auto-scroll */
  .slide-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-leave-active {
    transition: all 0.2s ease-in;
  }

  .slide-enter-from,
  .slide-leave-to {
    opacity: 0;
    transform: translate(-50%, -40%) !important;
  }

  /* Active citation styling during auto-scroll */
  :deep(.citation-link-active) {
    z-index: 10002;
    transform: scale(1.1);
    transition: all 0.3s ease;
  }
}

/* Ensure modal overlay works with auto-scroll */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10003; /* Above tooltip */
  transition: opacity 0.3s ease;
}

/* Transitions */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-enter-active, .slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter, .slide-leave-to {
  opacity: 0;
  transform: translate(-50%, -40%);
}

/* Hide citation link tooltip on hover on mobile. */
:deep(.citation-link.no-hover::after) {
  display: none !important;
}
/* Mobile optimization */
@media screen and (max-width: 768px) {
  .tooltip-modal {
    width: 90vw;
    max-height: 60vh;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

@media screen and (max-width: 768px) {
  :deep(.citation-link.no-hover::after),
  :deep(.citation-link.no-hover:hover::after) {
    display: none !important;
  }

  :deep(.citation-link:hover::after) {
    opacity: 0;
    visibility: hidden;
  }
}

/* Add to your existing styles */
:deep(.scrollable-tooltip::after) {
  overflow-y: auto !important;
  max-height: 500px !important;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1);
}

:deep(.scrollable-tooltip::after::-webkit-scrollbar) {
  width: 4px;
}

:deep(.scrollable-tooltip::after::-webkit-scrollbar-track) {
  background: rgba(255, 255, 255, 0.1);
}

:deep(.scrollable-tooltip::after::-webkit-scrollbar-thumb) {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
}

/* Make sure the tooltip pseudo-element can scroll */
:deep(.scrollable-tooltip::after) {
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}

</style>
