<template>
  <v-card class="modern-share-dialog" elevation="3">
    <div class="pa-6">
      <!-- Header -->
      <div class="d-flex align-center mb-6">
        <v-icon color="primary" class="mr-2">mdi-share-variant</v-icon>
        <h2 class="text-h6 font-weight-medium">Share CitationCast</h2>
      </div>

      <!-- Description -->
      <p class="text-body-2 text-grey-darken-1 mb-6">
        Share this audio source journey
      </p>

      <!-- Share URL -->
      <div class="share-url-container mb-6">
        <v-text-field
          v-model="shareUrl"
          readonly
          outlined
          dense
          hide-details
          class="share-input"
          :class="{ 'copied': isCopied }"
        >
          <template v-slot:append>
            <v-btn
              icon
              small
              @click="copyShareUrl"
              class="copy-button"
              :color="isCopied ? 'success' : 'grey darken-1'"
            >
              <v-icon>{{ isCopied ? 'mdi-check' : 'mdi-content-copy' }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <v-scale-transition>
          <div v-if="isCopied" class="copied-tooltip">
            Copied!
          </div>
        </v-scale-transition>
      </div>

      <!-- Visibility Toggle -->
      <div class="visibility-section">
        <p class="text-body-2 font-weight-medium text-grey-darken-2 mb-3">
          Visibility
        </p>
        <div class="visibility-toggle">
          <v-btn-toggle
            v-model="localMessage.visibility"
            mandatory
            class="visibility-buttons"
            @change="handleVisibilityChange"
          >
            <v-btn
              value="public"
              text
              class="visibility-btn"
            >
              <v-icon left size="18">mdi-earth</v-icon>
              Public
            </v-btn>
            <v-btn
              value="private"
              text
              class="visibility-btn"
            >
              <v-icon left size="18">mdi-lock</v-icon>
              Private
            </v-btn>
          </v-btn-toggle>
          <!-- Added visibility explanation text -->
          <p class="visibility-explanation text-caption text-grey-darken-1 mt-2">
            {{ visibilityExplanation }}
          </p>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ShareDialog',

  props: {
    message: {
      type: Object,
      required: true
    },
    shareUrl: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isCopied: false,
      localMessage: { ...this.message }
    }
  },

  computed: {
    visibilityExplanation() {
      return this.localMessage.visibility === 'public'
        ? 'Anyone with the link can access'
        : 'Only you can access'
    }
  },

  methods: {
    async copyShareUrl() {
      try {
        await navigator.clipboard.writeText(this.shareUrl);
        this.isCopied = true;
        setTimeout(() => {
          this.isCopied = false;
        }, 2000);
      } catch (err) {
        console.error('Failed to copy URL:', err);
      }
    },

    handleVisibilityChange() {
      this.$emit('update-visibility', { ...this.localMessage });
    }
  }
}
</script>

<style scoped>
.modern-share-dialog {
  max-width: 400px;
  border-radius: 12px !important;
}

.share-url-container {
  position: relative;
}

.share-input {
  transition: all 0.3s ease;
}

.share-input.copied ::v-deep .v-input__slot {
  border-color: var(--v-success-base) !important;
}

.copy-button {
  transition: all 0.3s ease !important;
}

.copied-tooltip {
  position: absolute;
  top: -24px;
  right: 0;
  background: var(--v-success-base);
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.visibility-toggle {
  width: 100%;
}

.visibility-buttons {
  width: 100%;
  border: 1px solid var(--v-primary-base) !important;
  border-radius: 8px;
}

.visibility-btn {
  flex: 1;
  height: 40px !important;
}

.visibility-btn.v-btn--active {
  color: var(--v-primary-base) !important;
}

.visibility-explanation {
  text-align: right;
}

.v-scale-transition-enter-active,
.v-scale-transition-leave-active {
  transition: all 0.2s ease;
}

.v-scale-transition-enter,
.v-scale-transition-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
