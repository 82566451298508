import { render, staticRenderFns } from "./ShareDialog.vue?vue&type=template&id=47963628&scoped=true"
import script from "./ShareDialog.vue?vue&type=script&lang=js"
export * from "./ShareDialog.vue?vue&type=script&lang=js"
import style0 from "./ShareDialog.vue?vue&type=style&index=0&id=47963628&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47963628",
  null
  
)

export default component.exports