<template>
  <div class="empty-state">
    <!-- Neural Grid Background -->
    <svg v-if="!popularChats.length" class="neural-grid" viewBox="0 0 100 100" preserveAspectRatio="none">
      <pattern :id="'grid-' + uniqueId" width="10" height="10" patternUnits="userSpaceOnUse">
        <path d="M 10 0 L 0 0 0 10" fill="none" stroke="currentColor" stroke-width="0.2" />
      </pattern>
      <rect width="100%" height="100%" :fill="'url(#grid-' + uniqueId + ')'" />
    </svg>

    <!-- Audio Waveform SVG -->
    <svg v-if="!popularChats.length" class="waveform-bg" viewBox="0 0 400 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient :id="'waveGradient-' + uniqueId" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style="stop-color: #2196F3" />
          <stop offset="50%" style="stop-color: #FF1493" />
          <stop offset="100%" style="stop-color: #00BFFF" />
        </linearGradient>
      </defs>
      <g class="waveform-bars" transform="translate(0, 100)">
        <rect
          v-for="n in 32"
          :key="'bar-' + n"
          :x="n * 12"
          y="0"
          width="6"
          :height="generateRandomHeight()"
          class="waveform-bar"
          :style="{
            animationDelay: n * 0.1 + 's',
            transform: 'translateY(-50%)',
            fill: 'url(#waveGradient-' + uniqueId + ')'
          }"
        />
      </g>
    </svg>

    <!-- Main Content -->
    <h2 class="unstruct-title">Deep Research, As You Move</h2>
    <p class="description">
      Ask anything. Hear actual sources.
    </p>
    <p class="description highlight">
      <strong>Zero effort. Pure knowledge.</strong>
    </p>

    <!-- Popular Chats Grid -->
    <div v-if="popularChats.length > 0" class="popular-chats">
      <h3 class="popular-title">Popular CitationCasts</h3>
      <div class="thumbnails-grid">
        <div
          v-for="chat in popularChats"
          :key="chat.share_id || uniqueId"
          class="chat-thumbnail"
          @click="navigateToChat(chat.share_id)"
        >
          <div class="thumbnail-content">
            <!-- Thumbnails Grid -->
            <div class="result-image">
              <div class="thumbnails-container">
                <div class="multi-thumbnails-grid">
                  <template v-for="(thumbnail, index) in getThumbnails(chat).thumbnails">
                    <div
                      :key="'thumb-' + index"
                      class="thumbnail-wrapper relative"
                    >
                      <div
                        class="thumbnail"
                        :style="{ backgroundImage: 'url(' + thumbnail + ')' }"
                        @error="handleImageError"
                      ></div>
                      <div class="thumbnail-placeholder" style="display: none;">
                        <div class="placeholder-gradient"></div>
                      </div>
                    </div>
                  </template>
                  <!-- Fill remaining slots with placeholders if less than 4 thumbnails -->
                  <template v-for="n in (4 - getThumbnails(chat).thumbnails.length)">
                    <div
                      :key="'placeholder-' + n"
                      class="thumbnail-wrapper relative"
                    >
                      <div class="thumbnail-placeholder">
                        <div class="placeholder-gradient"></div>
                      </div>
                    </div>
                  </template>
                </div>
                <div
                  v-if="getThumbnails(chat).remaining > 0"
                  class="remaining-count"
                >
                  +{{ getThumbnails(chat).remaining }}
                </div>
              </div>
            </div>

            <div class="chat-info">
              <h4 class="chat-question">{{ truncateText(chat.user_message, 60) }}</h4>
              <div class="chat-meta">
                <span class="chat-date">{{ formatDate(chat.created_at) }}</span>
                <span class="chat-views">
                  <v-icon small class="eye-icon">mdi-eye</v-icon>
                  {{ formatViewCount(chat.share_view_count) }}
                </span>
                <span v-if="getFirstSourceDomain(chat)" class="chat-source">
                  {{ getFirstSourceDomain(chat) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    popularChats: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    uniqueId() {
      return 'id_' + Math.random().toString(36).substr(2, 9);
    }
  },
  methods: {
    getThumbnails(chat) {
      try {
        if (!chat?.additional_data?.search_results) {
          return [];
        }

        // Get first 4 thumbnails
        const firstFour = chat.additional_data.search_results
          .slice(0, 4)
          .map(result => result.thumbnail || null)
          .filter(Boolean);

        // Calculate remaining count
        const remainingCount = Math.max(0, chat.additional_data.search_results.length - 4);

        return {
          thumbnails: firstFour,
          remaining: remainingCount
        };
      } catch (e) {
        return {
          thumbnails: [],
          remaining: 0
        };
      }
    },

    // Helper to format the remaining count
    formatRemaining(count) {
      return count > 0 ? `+${count}` : '';
    },
    getThumbnail(chat) {
      try {
        return chat &&
               chat.additional_data &&
               chat.additional_data.search_results &&
               chat.additional_data.search_results[0] &&
               chat.additional_data.search_results[0].thumbnail || null;
      } catch (e) {
        return null;
      }
    },
    navigateToChat(shareId) {
      if (!shareId) return;

      try {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('chat_id', shareId);
        window.location.href = currentUrl.toString();
      } catch (e) {
        console.error('Error navigating to chat:', e);
      }
    },
    truncateText(text, length) {
      if (!text) return '';
      text = text.toString();
      return text.length > length ? text.substr(0, length) + '...' : text;
    },
    formatDate(dateString) {
      if (!dateString) return '';
      try {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat(undefined, {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }).format(date);
      } catch (e) {
        return '';
      }
    },
    formatViewCount(count) {
      if (!count) return '0';
      return count > 1000 ? `${(count / 1000).toFixed(1)}k` : count;
    },
    getFirstSourceDomain(chat) {
      try {
        const url = chat.additional_data.search_results[0].url;
        return url ? new URL(url).hostname.replace('www.', '') : '';
      } catch (e) {
        return '';
      }
    },
    handleImageError(e) {
      if (e.target) {
        e.target.style.display = 'none';
        const wrapper = e.target.closest('.thumbnail-wrapper');
        if (wrapper) {
          const placeholder = wrapper.querySelector('.thumbnail-placeholder');
          if (placeholder) {
            placeholder.style.display = 'flex';
          }
        }
      }
    },
    generateRandomHeight() {
      return 15 + Math.random() * 30;
    }
  },
  beforeDestroy() {
    const elements = document.querySelectorAll(`#grid-${this.uniqueId}, #waveGradient-${this.uniqueId}`);
    elements.forEach(el => el.remove());
  }
}
</script>

<style scoped>
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 2rem;
  position: relative;
  margin-bottom: 125px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .empty-state {
    margin-bottom: 200px;
  }
}

.neural-grid {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.1;
  color: #2196F3;
  animation: gridPulse 4s ease-in-out infinite;
}

.nodes circle {
  fill: #FF1493;
  opacity: 0.5;
  animation: nodePulse 3s infinite;
}

@keyframes gridPulse {
  0%, 100% { opacity: 0.1; }
  50% { opacity: 0.15; }
}

@keyframes nodePulse {
  0%, 100% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.2);
  }
}

.waveform-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  height: auto;
  z-index: 1;
  opacity: 0.5;
}

.waveform-bar {
  opacity: 0.3;
  animation: barPulse 2s ease-in-out infinite;
  transform-origin: center;
}

@keyframes barPulse {
  0%, 100% {
    opacity: 0.3;
    transform: scaleY(0.9) translateY(-50%);
  }
  50% {
    opacity: 0.5;
    transform: scaleY(1.1) translateY(-50%);
  }
}

.unstruct-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #2196F3, #FF1493, #00BFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
  z-index: 2;
  text-align: center;
  background-size: 200% auto;
  animation: shimmer 3s linear infinite;
}

.description {
  color: rgba(33, 150, 243, 0.8);
  text-align: center;
  max-width: 32rem;
  line-height: 1.6;
  position: relative;
  z-index: 2;
  font-size: 1.25rem;
  opacity: 0.9;
}

.description.highlight strong {
  background: linear-gradient(90deg, #FF1493, #00BFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  opacity: 1;
}

@keyframes shimmer {
  0% { background-position: 200% center; }
  100% { background-position: -200% center; }
}

.popular-chats {
  width: 100%;
  max-width: 1200px;
  margin-top: 3rem;
  z-index: 2;
  position: relative;
}

.popular-title {
  font-size: 1.5rem;
  color: #2196F3;
  margin-bottom: 1.5rem;
  text-align: center;
}

.thumbnails-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.chat-thumbnail {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(33, 150, 243, 0.3);
}

.chat-thumbnail:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-color: rgba(33, 150, 243, 0.5);
}

.thumbnail-content {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.result-image {
  width: 100%;
  height: 160px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 1rem;
  background: rgba(0, 0, 0, 0.1);
}

.thumbnails-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.multi-thumbnails-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 4px;
  height: 100%;
}

.thumbnail {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  overflow: hidden;
}

.thumbnail-placeholder {
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #1e3c72 25%, #2a5298 50%, #1e3c72 75%);
  background-size: 200% 200%;
  animation: gradient 2s linear infinite;
}

.remaining-count {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.chat-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-views {
  display: flex;
  align-items: center;
  gap: 4px;
}

.chat-views i {
  font-size: 12px;
}

.chat-source {
  color: #2196F3;
  opacity: 0.8;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.chat-thumbnail:hover .thumbnail {
  transform: scale(1.05);
}

.thumbnail-placeholder {
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #1e3c72, #2a5298);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-question {
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  line-height: 1.4;
}

.chat-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  margin-top: auto;
}

.chat-source {
  font-size: 0.75rem;
  color: #2196F3;
  opacity: 0.8;
}

.placeholder-gradient {
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #1e3c72 25%, #2a5298 50%, #1e3c72 75%);
  background-size: 200% 200%;
  animation: gradient 2s linear infinite;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Dark mode adjustments */
.theme--dark .chat-thumbnail {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.theme--dark .chat-thumbnail:hover {
  border-color: rgba(33, 150, 243, 0.3);
}

.theme--dark .chat-question {
  color: rgba(255, 255, 255, 0.9);
}

.theme--dark .description {
  color: rgba(255, 255, 255, 0.8);
}

.theme--dark .chat-meta {
  color: rgba(255, 255, 255, 0.6);
}

.theme--dark .description.highlight strong {
  background: linear-gradient(90deg, #FF69B4, #40E0D0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.eye-icon {
  opacity: 0.7;
}
</style>
