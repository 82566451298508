/*
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import store from "@/store"

function login(to, from, next) {
  let token = localStorage.getItem("token")

  if (token) {
    store.commit("auth/SET_USER_LOGIN", token)
    next()
  } else {
    // extract the organization slug from the URL if it exists, otherwise use path
    let organizationSlug = to.path.organization
    if (!organizationSlug) {
      organizationSlug = window.location.pathname.split("/")[1] || "default"
    }

    // Preserve chat_id if it exists in the query parameters
    const chatId = to.query.chat_id

    // build the dynamic login path with query parameters if they exist
    let loginPath = `/${organizationSlug}/landing`
    if (chatId) {
      loginPath += `?chat_id=${chatId}`
    }

    // prevent redirect loop and preserve query parameters
    if (to.path !== `/${organizationSlug}/landing`) {
      next({
        path: loginPath,
        query: to.query  // This preserves all query parameters
      })
    } else {
      next()
    }
  }
}

export default {
  login,
}
